<template>
  <div id="addProd-content">
    <loader :loader="loader" />
    <md-card class="md-card-add-mdesign">
      <md-card-header class="md-card-header-icon md-card-header-primary">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <slot name="header" class="title">
          <h4>{{ $t('addProducts') }}</h4>
          <md-switch v-if="allow('{14e7f1ca-fb12-4460-9f87-a3c21ad16d05}')" v-model="showDependencies">{{ $t('showAll') }}</md-switch>
        </slot>
      </md-card-header>
      <md-card-content>
        <md-table v-model="productsToAdd" :key="renderKey" class="md-table-mdesign-configuration">
          <md-table-toolbar class="table-toolbar">
            <div class="md-layout md-gutter md-alignment-center">
              <div class="md-layout-item">
                <md-field>
                  <md-button @click.native="reduceCountAll" class="md-simple md-round">
                    <md-icon>remove</md-icon>
                  </md-button>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field>
                  <md-button class="md-simple md-round" @click.native="deselectAll()">{{
                    $t('deselectAll')
                  }}</md-button>
                </md-field>
              </div>

              <div class="md-layout-item">
                <md-field>
                  <md-button class="md-simple md-round" @click.native="selectAll()">{{ $t('selectAll') }}</md-button>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-button @click.native="addCountAll" class="md-simple md-round">
                  <md-icon>add</md-icon>
                </md-button>
              </div>
              <div class="md-layout-item">
                <md-field>
                  <md-button class="md-simple md-round" @click.native="unlimitedAll()">{{
                    $t('unlimitedAll')
                  }}</md-button>
                </md-field>
              </div>
            </div>
          </md-table-toolbar>

          <md-table-row :class="{ 'op-04': item.Count <= 0, 'hidden-dep': item.isDependency && !showDependencies }" slot="md-table-row" slot-scope="{ item }">
            <md-table-cell :md-label="$t('name')">
              {{ item.Name }}
            </md-table-cell>

            <md-table-cell class="mw-20">
              <span class="animated-icon" @click.prevent="reduceCount(item)">remove</span>
            </md-table-cell>
            <md-table-cell class="mw-80" :md-label="$t('count')">
              <md-field class="input-field">
                <label></label>
                <md-input inputmode="numeric" min="0" max="100" type="number" v-model="item.Count"></md-input>
              </md-field>
            </md-table-cell>

            <md-table-cell class="mw-20 pr-20">
              <span @dblclick.prevent="preventDefault" class="animated-icon" @click.prevent="addCount(item)">add</span>
            </md-table-cell>

            <md-table-cell class="fix-width-25" :md-label="$t('limited')">
              <md-field class="input-field just-cont-sb">
                <span v-if="item.Limited !== '9999-12-31'"
                  ><input type="date" v-model="item.Limited" @change="onLimitedChange(item)" />
                </span>
                <span class="md-input" v-else type="text">{{ $t('unlimited') }}</span>
                <div class="icon-container" :class="{ selected: item.Limited === '9999-12-31' }">
                  <span class="animated-icon md-pr-30" @click="setDate('unlimited', item)"> all_inclusive </span>
                </div>
              </md-field>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="md-layout">
          <div class="md-layout-item md-size-small-100 md-size-33">
            <md-field>
              <md-button class="md-simple md-round" @click.native="backStep()">{{ $t('back') }}</md-button>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-33 space-between"></div>
          <div class="md-layout-item md-size-small-100 md-size-33">
            <md-field class="content-right">
              <md-button class="md-primary" @click.native="confirmSelection()">{{ $t('confirm') }}</md-button>
            </md-field>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { useConfigurationProductLevelStore } from '../../../stores/configurationProductLevel.js'
import { useCompanyStore } from '../../../stores/company.js'
import { useUserStore } from '../../../stores/user.js'
import MdesignConfiguration from './MdesignConfiguration.vue'
import { permissionMixin } from '@/pages/RoleManagement/permissionMixin'

export default {
  mixins: [permissionMixin],
  data() {
    return {
      loader: false,
      renderKey: 0,
      masterModel: null,
      productsToAdd: [],
      showDependencies: false
    }
  },
  beforeMount() {
    if (this.configurationProductLevelStore.product.ID === '') {
      this.$router.push({ name: 'Company Products' })
      return
    }
    this.masterModel = this.configurationProductLevelStore.masterModel
    this.getProductsToAdd()
  },
  methods: {
    addCount(item) {
      if (item.Name === 'MDESIGN explorer') this.setLimitAll(item.Limited)
      if (item.Count <= 0) {
        item.Count = 1
      } else {
        item.Count++
      }
    },
    reduceCount(item) {
      item.Count > 0 ? item.Count-- : (item.Count = 0)
    },
    reduceCountAll() {
      this.productsToAdd.forEach((prod) => {
        this.reduceCount(prod)
      })
    },
    addCountAll() {
      this.productsToAdd.forEach((prod) => {
        this.addCount(prod)
      })
    },
    selectAll() {
      this.productsToAdd.forEach((level_1) => {
        level_1.Count = 1
      })
    },
    deselectAll() {
      this.productsToAdd.forEach((level_1) => {
        level_1.Count = 0
      })
    },
    getCount(i) {
      return i < 0 ? '0' : i
    },
    confirmSelection() {
      const copyConfigModel = JSON.parse(JSON.stringify(this.configurationProductLevelStore.configurationModel))

      if(!this.showDependencies)
        this.masterModel.ProductsLevel_1.forEach((level_1) => {
          if(level_1.Name.includes('techDependency') && this.productsToAdd.some(p => p.ID === level_1.ProductLevelOneID)) {
            var obj = this.productsToAdd.find(p => p.ID === level_1.ProductLevelOneID)
            obj.Count = 1
          }
        })
      this.productsToAdd.forEach((add) => {
        if (add.Count > 0) this.configurationProductLevelStore.addProductToConfiguration(add)
      })
      this.configurationProductLevelStore.resetConfigurationVorlage()
      const explorer = this.configurationProductLevelStore.configurationModel.ProductsLevel_1.find(
        (el) => el.LicName === 'E',
      )
      if (explorer) {
        this.configurationProductLevelStore.configurationModel.ProductsLevel_1.forEach((l_1) => {
          if (l_1.LicNameParent === 'E') {
            l_1.Count = explorer.Count
            if (l_1.ProductsLevel_2.length > 0) {
              l_1.ProductsLevel_2.forEach((l_2) => {
                if (l_2.Count > 0) l_2.Count = l_1.Count
                l_2.ProductsLevel_3.forEach((l_3) => {
                  if (l_3.Count > 0) l_3.Count = l_2.Cuont
                })
              })
            }
          }
        })
      }
      this.$router.push({ name: 'Mdesign Configuration Manager' })
    },
    setDate(limit, item) {
      switch (limit) {
        case 'unlimited':
          if (this.configurationProductLevelStore.licenceCreatorModel.EndDate !== '')
            item.Limited =
              item.Limited === '9999-12-31'
                ? this.configurationProductLevelStore.licenceCreatorModel.EndDate
                : '9999-12-31'
          else item.Limited = item.Limited === '9999-12-31' ? this.companyStore.getInOneYear() : '9999-12-31'
      }
    },
    unlimitedAll() {
      this.productsToAdd.forEach((prod) => {
        this.setDate('unlimited', prod)
      })
    },
    setLimitAll(limited) {
      this.productsToAdd.forEach((p) => {
        p.Limited = limited
      })
    },
    onLimitedChange(item) {
      if (item.Name === 'MDESIGN explorer') this.setLimitAll(item.Limited)
    },
    getProductsToAdd() {
      var skip = false
      this.configurationProductLevelStore.masterModel.ProductsLevel_1.forEach((master_1) => {
        skip = false
        var isDep = false
        if(master_1.Invisible)
          isDep = true
          
        // dependecies should be selectable
        // if (master_1.Invisible) {
        //   skip = true
        //   return
        // }
        
        this.configurationProductLevelStore.configurationModel.ProductsLevel_1.forEach((config_1) => {
          if (master_1.ProductLevelOneID === config_1.ProductLevelOneID ) {//|| master_1.Invisible
            skip = true
            return
          }
        })
        if (!skip) {
          var obj = {
            ID: master_1.ProductLevelOneID,
            Name: master_1.Name,
            Count: master_1.Count,
            Sorting: master_1.Sorting,
            Invisible: master_1.Invisible,
            Limited: this.configurationProductLevelStore.licenceCreatorModel.EndDate,
          }
          if(isDep)
            obj.isDependency = true
          if (
            obj.Limited === '' &&
            this.configurationProductLevelStore.configurationModel.ProductsLevel_1.some((obj) => obj.LicName === 'E')
          ) {
            const explorer = this.configurationProductLevelStore.configurationModel.ProductsLevel_1.find(
              (obj) => obj.LicName === 'E',
            )
            obj.Limited = explorer.Limited
          } else if (obj.Limited === '') obj.Limited = this.companyStore.getInOneYear()
          this.productsToAdd.push(obj)
        }
      })
      this.productsToAdd.sort((a, b) => a.Sorting - b.Sorting)
    },
    preventDefault(event) {
      event.preventDefault()
    },
    backStep() {
      this.$router.back()
    },
    toggleDependecies() {
      this.showDependencies = !this.showDependencies
      this.renderKey++
    }
  },
  setup() {
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    return { configurationProductLevelStore, userStore, companyStore }
  },
}
</script>

<style scoped lang="scss">
.md-list-action-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

span:hover {
  cursor: pointer;
}

.md-icon {
  margin: 0 0.5rem 0 0.5rem;
}

.md-table-row:hover {
  cursor: pointer;
}

.md-field:after {
  display: none !important;
}

.table-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.md-select-menu {
  max-width: none;
}

body > div.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  max-width: unset !important;
}

.op-04 {
  opacity: 0.4;
}

input[type='number'] {
  background: transparent;
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.md-table-cell {
  .md-table-cell-container {
    span {
      display: flex !important;
      align-items: center !important;
    }
  }
}

@keyframes changeGradient {
  /* Start from the left */
  0% {
    background-position: -100% 0;
  }

  /* Move to the right */
  100% {
    background-position: 100% 0;
  }
}

.md-table-row .animated-icon {
  visibility: hidden;
}

.md-table-row:hover .animated-icon {
  visibility: visible;
}

.content-right {
  display: flex;
  justify-content: flex-end;
}

.md-card-add-mdesign {
  max-width: 1200px;
}

#addProd-content {
  display: flex;
  justify-content: center;
}
.md-pl-30 {
  padding-left: 30px;
}
.hidden-dep {
  display: none !important;
}
</style>
