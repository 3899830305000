<template>
  <div class="md-layout create-product-content">
    <modal v-if="showModalProducts" @close="closeModal" class="modal-content" name="template-modal">
      <template v-slot:header>
        <h4 class="modal-title">{{ $t('selectProduct') + selectedProductLevel }}</h4>
      </template>
      <template slot="footer">
        <div class="md-layout space-evenly">
          <div class="md-layout-item md-size-33 just-center">
            <md-button @click="createProductLevel" class="md-round md-primary">{{ $t('create') }}</md-button>
          </div>
          <div class="md-layout-item md-size-33 just-center">
            <md-button
              @click="handle_Add_Selection"
              :disabled="level_Selection.length <= 0"
              class="md-round md-primary"
              >{{ $t('confirmSelected') }}</md-button
            >
          </div>
          <div class="md-layout-item md-size-33 just-center">
            <md-button @click="closeModal" class="md-round md-simple">{{ $t('cancel') }}</md-button>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div class="md-layout-item md-size-66">
          <md-field>
            <label>{{ $t('search') }}</label>
            <md-input v-model="search" type="text"></md-input>
          </md-field>
        </div>
        <div :key="renderKey" class="md-layout modal-content modal-list" v-if="selectedProductLevel === 1">
          <div
            class="md-layout-item md-size-100 product-level-list"
            v-for="level_1 in filteredLevelOne"
            :key="level_1.ProductLevelOneID"
          >
            <div class="list-row" :class="{ selected: level_1.selected }">
              <div class="list-item" @click="add_To_Selection(level_1)">
                {{ level_1.Name }}
              </div>
              <div class="controls">
                <div class="icon-container">
                  <span class="animated-icon" @click="handleAddLevelOne(level_1)">add</span>
                  <span class="animated-icon" @click="deleteProductLevel(level_1, 1)">remove</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :key="renderKey" class="md-layout modal-content modal-list" v-if="selectedProductLevel === 2">
          <div
            class="md-layout-item md-size-100 product-level-list"
            v-for="level_2 in filteredLevelTwo"
            :key="level_2.ProductLevelTwoID"
          >
            <div class="list-row" :class="{ selected: level_2.selected }">
              <div class="list-item" @click="add_To_Selection(level_2)">{{ level_2.Name }}</div>
              <div class="controls">
                <div class="icon-container">
                  <span class="animated-icon" @click="handleAddLevelTwo(level_2)">add</span>
                  <span class="animated-icon" @click="deleteProductLevel(level_2, 2)">remove</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :key="renderKey" class="md-layout modal-content modal-list" v-if="selectedProductLevel === 3">
          <div
            class="md-layout-item md-size-100 product-level-list"
            v-for="level_3 in filteredLevelThree"
            :key="level_3.ProductLevelThreeID"
          >
            <div class="list-row" :class="{ selected: level_3.selected }">
              <div class="list-item" @click="add_To_Selection(level_3)">{{ level_3.Name }}</div>
              <div class="controls">
                <div class="icon-container">
                  <span class="animated-icon" @click="handleAddLevelThree(level_3)">add</span>
                  <span class="animated-icon" @click="deleteProductLevel(level_3, 3)">remove</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal>

    <modal v-if="showModalCreateLevel" @close="closeModal" class="modal-content" name="template-modal">
      <template v-slot:header>
        <h4 class="modal-title">{{ $t('createProductLevel') + selectedProductLevel }}</h4>
      </template>
      <template slot="footer">
        <div class="create-level-one-content"></div>
      </template>
      <template v-slot:body>
        <EditConfigurationProductLevel
          :editProduct="editProduct"
          :productLevel="selectedProductLevel"
          @update="updateProducts"
          @close="closeModal"
        ></EditConfigurationProductLevel>
      </template>
    </modal>

    <modal v-if="showCopyConfigModal" @close="closeModal" class="modal-content" name="template-modal">
      <template v-slot:header>
        <div class="title-container">
          <h4 class="modal-title">{{ $t('copyOrCreateTitle') }}</h4>
        </div>
      </template>
      <template slot="footer">
        <div class=""></div>
      </template>
      <template v-slot:body>
        <div class="copy-config-content">
          <div
            class="copy-config-item list-row"
            v-for="config in configsToCopy"
            @click="copyAndAddMaster(config)"
            :key="config.ID"
          >
            {{ config.Info }}
          </div>
          <div class="pt-15 md-layout">
            <div class="md-layout-item md-size-33">
              <md-button class="md-round md-simple" @click="closeModal">{{ $t('cancel') }}</md-button>
            </div>
            <div class="md-layout-item md-size-33">
              <md-button class="md-round md-primary" @click="handleAddEmptyMaster">{{ $t('new') }}</md-button>
            </div>
          </div>
        </div>
      </template>
    </modal>

    <modal v-if="showModalCreateConfigurationProductLevel" class="modal-content" name="template-modal">
      <template v-slot:header>
        <h4 class="modal-title">{{ $t('createConfigurationHasProductLevel') + selectedProductLevel }}</h4>
      </template>
      <template v-slot:body>
        <EditConfigurationHasProductLevel
          :productLevel="selectedProductLevel"
          :configurationID="propConfigurationID"
          :productLevelOneID="propProductLevelOneID"
          :productLevelTwoID="propProductLevelTwoID"
          :productLevelThreeID="propProductLevelThreeID"
          :productLevelSelection="level_Selection"
          :editProduct="editConfigHasProduct"
          @close="closeModal"
          @update="updateProducts"
        ></EditConfigurationHasProductLevel>
      </template>
    </modal>

    <modal v-if="showModalTechDep" @close="closeModal" class="modal-content" name="template-modal">
      <template v-slot:header>
        <h4 class="modal-title">{{ $t('techDep') }}</h4>
      </template>
      <template slot="footer">
        <div class="create-level-one-content"></div>
      </template>
      <template v-slot:body>
        <EditTechnicalDependency
          :productID="product.ID"
          :productLevelOneID="propProductLevelOneID"
          @close="closeModal"
          @update="updateProducts"
        ></EditTechnicalDependency>
      </template>
    </modal>

    <ValidationObserver class="md-layout-item md-size-50 md-medium-size-100" v-slot="{ invalid }">
      <form>
        <loader :loader="loader" />
        <md-card class="card-content create-product-card">
          <md-card-header class="md-card-header-icon header-style" :class="getClass(headerColor)">
            <div>
              <div class="card-icon">
                <md-icon>settings</md-icon>
              </div>
              <h3 v-if="!edit" class="title">
                {{ $t('createProductCardTitle') }}
              </h3>
              <h3 v-else class="title">
                {{ $t('updateProductCardTitle') }}
              </h3>
              <h4>{{ $t('createProductText') }}</h4>
            </div>
          </md-card-header>

          <md-card-content>
            <form enctype="multipart/form-data">
              <div class="md-layout-item upload-document">
                <div class="file-input">
                  <div v-if="!imageRegular">
                    <div class="image-container">
                      <img :src="regularImg" title="" />
                    </div>
                  </div>
                  <div class="image-container" v-else>
                    <img :src="imageRegular" />
                  </div>

                  <div class="button-container">
                    <md-button
                      class="md-primary md-round md-fileinput ima-submit"
                      v-if="imageRegular"
                      @click="uploadImage"
                      >{{ $t('upload') }}
                    </md-button>
                    <md-button class="md-round md-simple" @click="removeImage" v-if="imageRegular">{{
                      $t('remove')
                    }}</md-button>

                    <md-button class="md-round md-primary md-fileinput ima-change" v-if="!imageRegular"
                      >{{ $t('selectImg') }}<input type="file" @change="onFileChange"
                    /></md-button>
                    <md-button class="md-round md-simple md-fileinput ima-change" v-else
                      >{{ $t('change') }}<input type="file" @change="onFileChange"
                    /></md-button>
                  </div>
                </div>
              </div>
            </form>

            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('productIDExtern') }}</label>
                  <md-input v-model="product.Product" type="text"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('productBuyLinkExtern') }}</label>
                  <md-input v-model="product.ProductBuyLinkExtern" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <ValidationProvider name="pName" rules="required" v-slot="{ passed, failed }">
                  <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                    <label>{{ $t('productName') + ' *' }}</label>
                    <md-input v-model="product.Name" type="text"></md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <ValidationProvider name="pLicName" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('productLicName') + ' *' }}</label>
                      <md-input v-model="product.LicName" type="text"></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <md-field>
                    <label>{{ $t('productRuntime') }}</label>
                    <md-input v-model="product.RuntimeInDays" type="number"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <md-field>
                    <label>{{ $t('productVersion') }}</label>
                    <md-input v-model="product.Version" type="number"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50">
                <ValidationProvider name="pTitle" rules="required" v-slot="{ passed, failed }">
                  <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                    <label>{{ $t('createProductTitle') + ' *' }}</label>
                    <md-input v-model="product.Title" type="text"></md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <ValidationProvider name="pTitleEN" rules="required" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('productTitleEN') + ' *' }}</label>
                      <md-input v-model="product.TitleEN" type="text"></md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('productSubtitle') }}</label>
                  <md-input v-model="product.Subtitle" type="text"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <md-field>
                    <label>{{ $t('productSubtitleEN') }}</label>
                    <md-input v-model="product.SubtitleEN" type="text"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field class="md-has-value no-bg">
                  <label>{{ $t('productTeaser') }}</label>
                  <md-textarea v-model="product.Teaser"></md-textarea>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <md-field class="md-has-value no-bg">
                    <label>{{ $t('productTeaserEN') }}</label>
                    <md-textarea v-model="product.TeaserEN"></md-textarea>
                  </md-field>
                </div>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('productLink') }}</label>
                  <md-input v-model="product.Link" type="text"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <md-field>
                    <label>{{ $t('productLinkEN') }}</label>
                    <md-input v-model="product.LinkEN" type="text"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <md-field class="md-has-value">
                    <label>{{ $t('productStartTime') }}</label>
                    <input class="md-input" type="date" v-model="product.Starttime" />
                  </md-field>
                </div>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-50">
                <div>
                  <md-field class="md-has-value">
                    <label>{{ $t('productEndTime') }}</label>
                    <input class="md-input" type="date" v-model="product.Endtime" />
                  </md-field>
                </div>
              </div>

              <div class="update-profile-button">
                <div class="md-layout-item md-size-100 text-right" :key="renderKey">
                  <md-button
                    v-if="!edit"
                    :disabled="invalid"
                    @click="handleCreate"
                    class="md-raised md-primary mt-4 md-round"
                    >{{ $t('createProduct') }}
                  </md-button>
                  <md-button v-else :disabled="invalid" @click="handleUpdate" class="md-raised md-primary mt-4 md-round"
                    >{{ $t('save') }}
                  </md-button>
                </div>
                <div class="text-center"></div>

                <form enctype="multipart/form-data"></form>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>
    </ValidationObserver>

    <div v-if="allow('{0bc5cd90-e97e-44fc-94ea-014b2f57a6b4}')" class="md-layout-item md-size-50 md-medium-size-100">
      <md-card>
        <tabs
          :tab-name="[$t('productLevel') + '1', $t('productLevel') + '2', $t('productLevel') + '3']"
          :tab-icon="['looks_one', 'looks_two', 'looks_3']"
          class="tab-product-levels r-3"
          nav-pills-icons
          plain
          color-button="primary"
        >
          <!-- <div class="card-icon">
          <md-icon>settings</md-icon>
        </div> -->
          <h4 v-if="masterConfig !== null" class="title text-center" slot="header-title">
            {{ $t('masterTemplate') }} - <small class="description">{{ product.Name }}</small>
          </h4>
          <h4 v-else class="title text-center" slot="header-title">
            {{ $t('noMasterFound') }} - <small class="description">{{ $t('createNewMaster') }}</small>
          </h4>
          <template slot="tab-pane-1">
            <div class="md-layout">
              <div
                v-if="masterConfig !== null && masterModel.ProductsLevel_1.length === 0"
                class="md-layout-item md-size-100"
              >
                <span>{{ $t('noProductsLevelOneYet') }}</span>
              </div>
              <div
                v-for="level_one in masterModel.ProductsLevel_1"
                class="level-one-container"
                :class="{
                  'has-items': level_one.ProductsLevel_2.length > 0,
                  selected: selectedLevelOne.ProductLevelOneID === level_one.ProductLevelOneID,
                }"
                :key="level_one.ProductLevelOneID"
                @click="selectLevelOne(level_one)"
              >
                <span>{{ level_one.Name }}</span>
                <div class="controls">
                  <div class="icon-container">
                    <span class="animated-icon" @click="handleEditLevelOne(level_one)">edit</span>
                    <md-tooltip>{{ $t('editProductTooltip') }}</md-tooltip>
                  </div>
                  <div class="icon-container">
                    <span class="animated-icon" @click="handleConfigHasProd_1(level_one)">add</span>
                    <md-tooltip>{{ $t('editProductLevelTooltip') }}</md-tooltip>
                  </div>
                  <div class="icon-container">
                    <span class="animated-icon" @click="handleTechDep(level_one)">rule</span>
                    <md-tooltip>{{ $t('addTechDepTooltip') }}</md-tooltip>
                  </div>
                  <div class="icon-container">
                    <span class="animated-icon" @click="handleDeleteConfigProd(level_one, 1)">delete</span>
                    <md-tooltip>{{ $t('deleteFromMasterTooltip') }}</md-tooltip>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-50 center"></div>
              <div class="md-layout-item md-size-50 center">
                <md-button v-if="!noMasterModel" @click="askChooseLevelOne" class="md-primary md-round">{{
                  $t('add')
                }}</md-button>
                <md-button v-else @click="handleCreateMasterConfig" class="md-primary md-round"
                  >{{ $t('create') }}<md-tooltip>{{ $t('createMaster') }}</md-tooltip></md-button
                >
              </div>
            </div>
          </template>
          <template slot="tab-pane-2">
            <div class="md-layout">
              <div v-if="!selectedLevelOne.Name" class="md-layout-item md-size-100">
                <span>{{ $t('noProductLevelOneSelected') }}</span>
              </div>
              <div
                v-for="level_two in selectedLevelOne.ProductsLevel_2"
                :class="{
                  'has-items': level_two.ProductsLevel_3.length > 0,
                  selected: selectedLevelTwo.ProductLevelTwoID === level_two.ProductLevelTwoID,
                }"
                class="level-two-container"
                :key="level_two.ProductLevelTwoID"
                @click="selectLevelTwo(level_two)"
              >
                <span>{{ level_two.Name }}</span>
                <div class="controls">
                  <div class="icon-container">
                    <span class="animated-icon" @click="handleEditLevelTwo(level_two)">edit</span>
                    <md-tooltip>{{ $t('editProductTooltip') }}</md-tooltip>
                  </div>
                  <div class="icon-container">
                    <span class="animated-icon" @click="handleConfigHasProd_2(level_two)">add</span>
                    <md-tooltip>{{ $t('editProductLevelTooltip') }}</md-tooltip>
                  </div>
                  <div class="icon-container">
                    <span class="animated-icon" @click="handleDeleteConfigProd(level_two, 2)">delete</span>
                    <md-tooltip>{{ $t('deleteFromMasterTooltip') }}</md-tooltip>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-50 center"></div>
              <div class="md-layout-item md-size-50 center">
                <md-button :disabled="!levelOneIsSelected" @click="askChooseLevelTwo" class="md-primary md-round">{{
                  $t('add')
                }}</md-button>
              </div>
            </div>
          </template>
          <template slot="tab-pane-3">
            <div class="md-layout">
              <div v-if="!selectedLevelTwo.Name" class="md-layout-item md-size-100">
                <span>{{ $t('noProductLevelTwoSelected') }}</span>
              </div>
              <div
                v-for="level_three in selectedLevelTwo.ProductsLevel_3"
                class="level-three-container"
                :key="level_three.ProductLevelThreeID"
              >
                <span>{{ level_three.Name }}</span>
                <div class="controls">
                  <div class="icon-container">
                    <span class="animated-icon" @click="handleEditLevelThree(level_three)">edit</span>
                    <md-tooltip>{{ $t('editProductTooltip') }}</md-tooltip>
                  </div>
                  <div class="icon-container">
                    <span class="animated-icon" @click="handleConfigHasProd_3(level_three)">add</span>
                    <md-tooltip>{{ $t('editProductLevelTooltip') }}</md-tooltip>
                  </div>
                  <div class="icon-container">
                    <span class="animated-icon" @click="handleDeleteConfigProd(level_three, 3)">delete</span>
                    <md-tooltip>{{ $t('deleteFromMasterTooltip') }}</md-tooltip>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-50 center"></div>
              <div class="md-layout-item md-size-50 center">
                <md-button :disabled="!levelTwoIsSelected" @click="askChooseLevelThree" class="md-primary md-round">{{
                  $t('add')
                }}</md-button>
              </div>
            </div>
          </template>
        </tabs>
      </md-card>
    </div>
    <div v-else class="no-model"></div>
  </div>
</template>
<script>
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '@/stores/user'
import { useConfigurationProductLevelStore } from '@/stores/configurationProductLevel'
import Swal from 'sweetalert2'
import image_placeholder from '../../../../public/img/image_placeholder.jpg'
import pdfImg from '../../../../public/img/PDF_file_icon.svg.png'
import { Tabs } from '@/components'
import Modal from '@/components/Modal.vue'
import EditConfigurationProductLevel from './EditConfigurationProductLevel.vue'
import EditConfigurationHasProductLevel from './EditConfigurationHasProductLevel.vue'
import EditTechnicalDependency from './EditTechnicalDependency.vue'
import { extend } from 'vee-validate'
import { required, min_value, email, numeric } from 'vee-validate/dist/rules'
import { permissionMixin } from '@/pages/RoleManagement/permissionMixin'

export default {
  mixins: [permissionMixin],
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
  },
  components: { Modal, EditConfigurationProductLevel, Tabs, EditConfigurationHasProductLevel, EditTechnicalDependency }, //
  data() {
    return {
      renderKey: 0,
      search: '',
      edit: false,
      pdf: pdfImg,
      loader: false,
      image: null,
      newAvatar: null,
      sliders: {
        simple: 5,
      },
      files: [],
      regularImg: image_placeholder,
      imageRegular: '',
      product: {
        ID: '',
        ProductIDExtern: 0,
        ProductCodeExtern: '',
        ProductBuyLinkExtern: '',
        Name: '',
        Title: '',
        Subtitle: '',
        Teaser: '',
        Link: '',
        Image: 0,
        TitleEN: '',
        SubtitleEN: '',
        TeaserEN: '',
        LinkEN: '',
        ImageEN: 0,
        RuntimeInDays: 0,
        Starttime: '',
        Endtime: '',
        Version: 0,
        LicName: '',
      },
      editProduct: null,
      selectedLevelOne: {},
      selectedLevelTwo: {},
      masterModel: {
        ProductsLevel_1: [],
      },
      showModalProducts: false,
      showModalCreateLevel: false,
      showModalCreateConfigurationProductLevel: false,
      showModalTechDep: false,
      selectedProductLevel: null,
      productsLevel_1: [],
      productsToAddLevel_1: [],
      productsLevel_2: [],
      productsToAddLevel_2: [],
      productsLevel_3: [],
      productsToAddLevel_3: [],
      noMasterModel: true,
      configurationHasProduct: {},
      propProductLevelOneID: null,
      propProductLevelTwoID: null,
      propProductLevelThreeID: null,
      propConfigurationID: '',
      level_Selection: [],
      levelTwoSelection: [],
      editConfigHasProduct: false,
      levelOneIsSelected: false,
      levelTwoIsSelected: false,
      masterConfig: null,
      configsToCopy: [],
      showCopyConfigModal: false,
    }
  },
  async beforeMount() {
    this.loader = true

    const date = new Date()
    const now = date.toISOString()
    this.configurationProductLevelStore.resetMasterModel()
    // if (!this.userStore.role.includes('Admin')) this.$router.push({ name: 'Login' })

    if (this.configurationProductLevelStore.product.ID) {
      this.product = this.configurationProductLevelStore.product

      this.product.Starttime = this.product.Starttime.includes('T')
        ? this.product.Starttime.split('T')[0]
        : this.product.Starttime
      this.product.Endtime = this.product.Endtime.includes('T')
        ? this.product.Endtime.split('T')[0]
        : this.product.Endtime
      this.edit = true
      if (this.product.Image === 1) this.getImage()

      this.masterConfig = null
      await this.getMasterConfigAndCreateModel()
    }
    this.btnName = this.edit ? this.$t('save') : this.$t('createProduct')
    const translationKey = this.edit ? this.$t('pageTitleEditProduct') : this.$t('pageTitleCreateNewProduct')
    this.$changePageTitle(this.$t(translationKey))
    this.loader = false
  },
  methods: {
    async handleAddEmptyMaster() {
      const now = this.companyStore.getNow()
      var newObj = {
        CompanyID: '',
        ContactID: '',
        Info: 'Master: ' + this.product.Title,
        CreatedBy: this.userStore.userData.ID,
        Created: now,
        ModifiedBy: this.userStore.userData.ID,
        Modified: now,
      }

      var resNewConfig = await this.configurationProductLevelStore.createConfiguration(newObj, this.userStore.token)
      if (resNewConfig.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: this.$t('createConfiguration') + ' ' + resNewConfig.data.Message,
        })
        return
      } else {
        const newConfigID = resNewConfig.data
        var prodConfigObj = {
          ProductID: this.product.ID,
          ConfigurationID: newConfigID,
          Master: true,
          Template: false,
          Sorting: 1,
          CreatedBy: this.userStore.userData.ID,
          Created: now,
          ModifiedBy: this.userStore.userData.ID,
          Modified: now,
        }

        var resProdConfig = await this.configurationProductLevelStore.createProductHasConfiguration(
          prodConfigObj,
          this.userStore.token,
        )
        if (resProdConfig.status !== 200) {
          Swal.fire({
            icon: 'error',
            titel: this.$t('error'),
            html: 'errorLinkageProdConfig' + ' ' + resProdConfig.data.Message,
          })
          return
        } else {
          this.getMasterConfigAndCreateModel(newConfigID, this.userStore.token)
        }
      }
      this.closeModal()
    },
    async handleCreateMasterConfig() {
      var ask = await Swal.fire({
        icon: 'question',
        title: this.$t('createMasterTitle'),
        html: this.$t('createmasterText'),
        showCancelButton: true,
      }).then((result) => {
        return result
      })
      if (!ask.isConfirmed) return
      var resAllConfigs = await this.configurationProductLevelStore.getAllConfigurations(this.userStore.token)

      if (resAllConfigs.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: 'errorGetAllConfigs' + ' ' + resAllConfigs.data.Message,
        })
        return
      } else {
        // this.configsToCopy = resAllConfigs.data.filter()
        this.configsToCopy = resAllConfigs.data.filter((obj) => obj.ContactID === '' && obj.CompanyID === '')
        this.showCopyConfigModal = true
      }
    },
    async getMasterConfigAndCreateModel() {
      var resProdConfigs = await this.configurationProductLevelStore.getProductHasConfiguration(
        this.product.ID,
        this.userStore.token,
      )
      if (resProdConfigs.status === 200 && resProdConfigs.data !== '') {
        resProdConfigs.data.forEach((config) => {
          if (config.Master) {
            this.noMasterModel = false
            this.masterConfig = config
          }
        })
        if (this.masterConfig !== null) {
          await this.createMasterModel(this.masterConfig.ConfigurationID)
        }
      }
      if (this.masterConfig !== null) {
        if (this.productsLevel_1.length === 0) {
          await this.configurationProductLevelStore.getProductLevels_1(this.userStore.token)
        }
        if (this.productsLevel_2.length === 0) {
          await this.configurationProductLevelStore.getProductLevels_2(this.userStore.token)
        }
        if (this.productsLevel_3.length === 0) {
          await this.configurationProductLevelStore.getProductLevels_3(this.userStore.token)
        }

        this.productsLevel_1 = this.configurationProductLevelStore.productsLevel_1
        this.productsLevel_1.sort((a, b) => a.Sorting - b.Sorting)
        this.productsLevel_2 = this.configurationProductLevelStore.productsLevel_2
        this.productsLevel_2.sort((a, b) => a.Sorting - b.Sorting)
        this.productsLevel_3 = this.configurationProductLevelStore.productsLevel_3
        this.productsLevel_3.sort((a, b) => a.Sorting - b.Sorting)
        this.getProductsToAddLevel_1()
        this.getProductsToAddLevel_2()
        this.getProductsToAddLevel_3()
      }
    },
    async copyAndAddMaster(config) {
      var ask = await Swal.fire({
        icon: 'question',
        title: this.$t('askCopyConfigTitle'),
        html: this.$t('askCopyConfigText') + '<br>' + config.Info,
        showCancelButton: true,
      }).then((result) => {
        return result
      })

      if (!ask.isConfirmed) return

      const now = this.companyStore.getNow()
      var copyObj = {
        configurationID: config.ID,
        configurationInfo: 'Master: ' + this.product.Title,
        creator: this.userStore.userData.ID,
      }
      var resCopyConfig = await this.configurationProductLevelStore.copyCompleteConfiguration(
        copyObj,
        this.userStore.token,
      )

      if (resCopyConfig.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: this.$t('errorCopyConfiguration') + ' ' + resCopyConfig.data.Message,
        })
        return
      } else {
        const newConfigID = resCopyConfig.data

        var masterObj = {
          ProductID: this.product.ID,
          ConfigurationID: newConfigID,
          Master: true,
          Template: false,
          Sorting: 1,
          CreatedBy: this.userStore.userData.ID,
          Created: now,
          ModifiedBy: this.userStore.userData.ID,
          Modified: now,
        }
        var resProdHasConfig = await this.configurationProductLevelStore.createProductHasConfiguration(
          masterObj,
          this.userStore.token,
        )

        if (resProdHasConfig.status !== 200) {
          Swal.fire({
            icon: 'error',
            title: this.$t('error'),
            html: this.$t('createConfiguration'),
          })
          this.closeModal()
          return
        } else {
          Swal.fire({
            icon: 'success',
            title: this.$t('success'),
            html: this.$t('successCopyConfig'),
          }).then(() => {
            this.getMasterConfigAndCreateModel(newConfigID)
          })
        }
      }

      this.closeModal()
    },
    async createMasterModel(configID) {
      var res = await this.configurationProductLevelStore.createMasterConfigurationModel(configID, this.userStore.token)
      this.masterModel = this.configurationProductLevelStore.masterModel
    },
    async updateProducts() {
      this.closeModal()
      await this.createMasterModel(this.masterModel.ConfigurationID)
      this.getProductsToAddLevel_1()
      this.getProductsToAddLevel_2()
      this.getProductsToAddLevel_3()
      this.updateSelected()
      this.renderKey++
    },
    updateSelected()
    {
      //check selected lvl1
      if(this.selectedLevelOne)
      {
        var findSelected = this.masterModel.ProductsLevel_1.filter(p=>p.ProductLevelOneID == this.selectedLevelOne.ProductLevelOneID)
        if (findSelected?.length > 0) this.selectedLevelOne = findSelected[0]
      }
      if(this.selectedLevelTwo && this.selectedLevelOne)
      {
      var findSelected = this.selectedLevelOne.ProductsLevel_2.filter(p=>p.ProductLevelTwoID == this.selectedLevelTwo.ProductLevelTwoID)
        if (findSelected?.length > 0) this.selectedLevelTwo = findSelected[0]
      }
      },
    selectLevelOne(item) {
      if (this.selectedLevelOne.ProductLevelOneID !== item.ProductLevelOneID) {
        this.selectedLevelOne = item
        this.levelOneIsSelected = true
        this.selectedLevelTwo = {}
        this.levelTwoIsSelected = false
      } else {
        this.selectedLevelOne = {}
        this.selectedLevelTwo = {}
        this.levelOneIsSelected = false
        this.levelTwoIsSelected = false
      }
    },
    selectLevelTwo(item) {
      if (this.selectedLevelTwo.ProductLevelTwoID !== item.ProductLevelTwoID) {
        this.selectedLevelTwo = item
        this.levelTwoIsSelected = true
      } else {
        this.selectedLevelTwo = {}
        this.levelTwoIsSelected = false
      }
    },
    createProductLevel() {
      this.showModalCreateLevel = true
      this.showModalProducts = false
      this.editProduct = null
    },
    askChooseLevelOne() {
      this.showModalProducts = true
      this.propProductLevelOneID = null
      this.propProductLevelTwoID = null
      this.propProductLevelThreeID = null
      this.removeSelectedOne()
      this.level_Selection = []
      this.selectedProductLevel = 1
    },
    add_To_Selection(item) {
      if (!item.selected) {
        item.selected = true
        this.level_Selection.push(item)
        this.renderKey++
      } else {
        const index = this.level_Selection.findIndex((obj) => obj.ID === item.ID)
        this.level_Selection.splice(index, 1)
        item.selected = false
        this.renderKey++
      }
    },

    handle_Add_Selection() {
      if (this.selectedProductLevel === 2) {
        this.propProductLevelOneID = this.selectedLevelOne.ProductLevelOneID
      } else if (this.selectedProductLevel === 3) {
        this.propProductLevelTwoID = this.selectedLevelTwo.ProductLevelTwoID
      }
      this.propConfigurationID = this.masterModel.ConfigurationID
      this.showModalProducts = false
      this.showModalCreateConfigurationProductLevel = true
    },
    handleConfigHasProd_1(item) {
      this.editConfigHasProduct = true
      this.selectedProductLevel = 1
      this.propConfigurationID = this.masterModel.ConfigurationID
      this.propProductLevelOneID = item.ProductLevelOneID
      this.showModalProducts = false
      this.showModalCreateConfigurationProductLevel = true
    },
    handleConfigHasProd_2(item) {
      this.editConfigHasProduct = true
      this.selectedProductLevel = 2
      this.propConfigurationID = this.masterModel.ConfigurationID
      this.propProductLevelOneID = item.ProductLevelOneID
      this.propProductLevelTwoID = item.ProductLevelTwoID
      this.showModalProducts = false
      this.showModalCreateConfigurationProductLevel = true
    },
    handleConfigHasProd_3(item) {
      this.editConfigHasProduct = true
      this.selectedProductLevel = 3
      this.propConfigurationID = this.masterModel.ConfigurationID
      this.propProductLevelOneID = this.selectedLevelOne.ProductLevelOneID
      this.propProductLevelTwoID = item.ProductLevelTwoID
      this.propProductLevelThreeID = item.ProductLevelThreeID
      this.showModalProducts = false
      this.showModalCreateConfigurationProductLevel = true
    },
    handleAddLevelOne(item) {
      this.editConfigHasProduct = false
      this.propConfigurationID = this.masterModel.ConfigurationID
      this.propProductLevelOneID = item.ID
      this.showModalProducts = false
      this.showModalCreateConfigurationProductLevel = true
    },
    handleEditLevelOne(item) {
      this.editProduct = item
      this.selectedProductLevel = 1
      this.showModalCreateLevel = true
      this.showModalProducts = false
    },
    handleEditLevelTwo(item) {
      this.editProduct = item
      this.selectedProductLevel = 2
      this.showModalCreateLevel = true
      this.showModalProducts = false
    },
    askChooseLevelTwo() {
      this.showModalProducts = true
      this.propProductLevelOneID = null
      this.propProductLevelTwoID = null
      this.propProductLevelThreeID = null
      this.removeSelectedTwo()
      this.level_Selection = []
      this.selectedProductLevel = 2
    },
    handleAddLevelTwo(item) {
      this.editConfigHasProduct = false
      this.propConfigurationID = this.masterModel.ConfigurationID
      this.selectedProductLevel = 2
      this.propProductLevelTwoID = item.ID
      this.propProductLevelOneID = this.selectedLevelOne.ProductLevelOneID
      this.showModalCreateConfigurationProductLevel = true
      this.showModalProducts = false
    },
    handleEditLevelThree(item) {
      this.editProduct = item
      this.selectedProductLevel = 3
      this.showModalCreateLevel = true
      this.showModalProducts = false
    },
    askChooseLevelThree() {
      this.showModalProducts = true
      this.propProductLevelOneID = null
      this.propProductLevelTwoID = null
      this.propProductLevelThreeID = null
      this.removeSelectedThree()
      this.level_Selection = []
      this.selectedProductLevel = 3
    },
    handleAddLevelThree(item) {
      this.editConfigHasProduct = false
      this.propConfigurationID = this.masterModel.ConfigurationID
      this.selectedProductLevel = 3
      this.propProductLevelThreeID = item.ID
      this.propProductLevelOneID = this.selectedLevelOne.ProductLevelOneID
      this.propProductLevelTwoID = this.selectedLevelTwo.ProductLevelTwoID

      this.showModalCreateConfigurationProductLevel = true
      this.showModalProducts = false
    },
    handleTechDep(item) {
      this.propProductLevelOneID = item.ProductLevelOneID
      this.showModalTechDep = true
    },
    handleDeleteConfigProd(item, level) {
      if (level === 1) {
        if (item.ProductsLevel_2.length > 0) {
          Swal.fire({
            icon: 'error',
            title: this.$t('error'),
            html: this.$t('errorProductLevelsText1'),
          })
          return
        }
      }
      if (level === 2) {
        if (item.ProductsLevel_3.length > 0) {
          Swal.fire({
            icon: 'error',
            title: this.$t('error'),
            html: this.$t('errorProductLevelsText2'),
          })
          return
        }
      }
      Swal.fire({
        icon: 'warning',
        title: this.$t('deleteTitle'),
        html: this.$t('deleteConfigProductText'),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (level === 1) {
            this.deleteConfigProduct_1(item)
          } else if (level === 2) {
            this.deleteConfigProduct_2(item)
          } else if (level === 3) {
            this.deleteConfigProduct_3(item)
          }
        }
      })
    },
    async deleteConfigProduct_1(item) {
      var res = await this.configurationProductLevelStore.deleteConfigurationHasProductLevelOneCombi(
        item.ProductLevelOneID,
        this.masterModel.ConfigurationID,
        this.userStore.token,
      )
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('deleteConfigProductTextSuccess'),
          showCancelButton: true,
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: res.statusText,
          showCancelButton: true,
        })
      }

      this.updateProducts()
    },   
    async deleteProductLevel(item, level) {
      var res = await this.configurationProductLevelStore.deleteProductLevel(item.ID, level, this.userStore.token)
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('deleteProductTextSuccess'),
          showCancelButton: true,
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: res.statusText,
          showCancelButton: true,
        })
      }
      this.updateProducts()
    },
    async deleteConfigProduct_2(item) {
      var res = await this.configurationProductLevelStore.deleteConfigurationHasProductLevelTwoCombi(
        item.ProductLevelOneID,
        item.ProductLevelTwoID,
        this.masterModel.ConfigurationID,
        this.userStore.token,
      )
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('deleteConfigProductTextSuccess'),
          showCancelButton: true,
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: res.statusText,
          showCancelButton: true,
        })
      }
      this.updateProducts()
    },
    async deleteConfigProduct_3(item) {
      var res = await this.configurationProductLevelStore.deleteConfigurationHasProductLevelThreeCombi(
        item.ProductLevelTwoID,
        item.ProductLevelThreeID,
        this.masterModel.ConfigurationID,
        this.userStore.token,
      )
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('deleteConfigProductTextSuccess'),
          showCancelButton: true,
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: res.statusText,
          showCancelButton: true,
        })
      }
      this.updateProducts()
    },
    removeSelectedOne() {
      this.productsLevel_1.forEach((el) => {
        el.selected = false
      })
    },
    removeSelectedTwo() {
      this.productsLevel_2.forEach((el) => {
        el.selected = false
      })
    },
    removeSelectedThree() {
      this.productsLevel_3.forEach((el) => {
        el.selected = false
      })
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    onFileChange(e) {
      this.files = e.target.files || e.dataTransfer.files
      if (!this.files.length) return
      this.createImage(this.files[0])
    },
    removeImage: function () {
      this.imageRegular = ''
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      if (file.type.match('\pdf')) {
        reader.onload = () => {
          this.imageRegular = this.pdf
        }
        reader.readAsDataURL(file)
        this.pdfName = file.name
        return
      }

      reader.onload = (e) => {
        vm.imageRegular = e.target.result
      }
      reader.readAsDataURL(file)

      this.pdfName = ''
    },
    async uploadImage() {
      var productID = this.configurationProductLevelStore.product.ID
      var res = await this.companyStore.uploadProductImage(this.files[0], this.userStore.token, productID)
      if (res.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('errorUploadImage'),
          html: res.statusText,
        }).then((result) => {
          return result
        })
        return
      } else {
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('successUploadImage'),
        }).then((result) => {
          return result
        })
        return
      }
    },
    async handleCreate() {
      var question = Swal.fire({
        icon: 'info',
        title: this.$t('askCreateProduct'),
        showCancelButton: true,
      }).then((result) => {
        return result
      })

      if (!(await question).isConfirmed) return

      var res = await this.companyStore.createProduct(this.product, this.userStore.token)
      if (res.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('errorCreateProduct'),
        }).then((result) => {
          return result
        })
        return
      }
      this.product.ID = res.data
      this.configurationProductLevelStore.product = this.product
      this.edit = true
      if (this.imageRegular !== '') this.uploadImage()
    },
    async handleUpdate() {
      var question = await Swal.fire({
        icon: 'info',
        title: this.$t('askUpdateProduct'),

        showCancelButton: true,
      }).then((result) => {
        return result
      })

      if (!(await question).isConfirmed) return

      var resUpdate = await this.companyStore.updateProduct(
        this.configurationProductLevelStore.product,
        this.userStore.token,
      )

      if (resUpdate.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('errorUpdateProduct'),
          html: resUpdate.statusText,
        }).then((result) => {
          return result
        })
        return
      }
      if (this.imageRegular !== '') {
        var productID = this.configurationProductLevelStore.product.ID
        var res = await this.companyStore.uploadProductImage(this.files[0], this.userStore.token, productID)
        if (res.status !== 200) {
          Swal.fire({
            icon: 'error',
            title: this.$t('errorUploadImage'),
            html: resUpdate.statusText,
          }).then((result) => {
            return result
          })
          return
        }
      }
      Swal.fire({
        icon: 'success',
        title: this.$t('success'),
        html: this.$t('successUpdateProduct'),
      }).then((result) => {
        return result
      })
    },
    async getImage() {
      const token = this.userStore.token
      // var idFromTable = this.companyStore.productData.editProduct.ID
      var idFromTable = this.configurationProductLevelStore.product.ID
      var tableName = 'product'
      var resDataFile = await this.companyStore.getFileReferenceByTable(idFromTable, tableName, token)
      if (resDataFile.status !== 200 && resDataFile.status !== 404) {
        Swal.fire({
          icon: 'error',
          title: this.$t('errorDownloadImage'),
        }).then((result) => {
          return result
        })
        return
      }
      if (resDataFile.status === 404) return
      var dataFileID = resDataFile.data[0].DataFileID
      var resFile = await this.companyStore.getDownloadFile(dataFileID, false, token)
      if (resFile.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('errorDownloadImage'),
        }).then((result) => {
          return result
        })
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(resFile.data)
      reader.onload = () => {
        this.imageRegular = reader.result
      }
    },
    closeModal() {
      this.showModalProducts = false
      this.showModalCreateLevel = false
      this.showModalCreateConfigurationProductLevel = false
      this.showModalTechDep = false
      this.showCopyConfigModal = false
    },
    getProductsToAddLevel_1() {
      if (this.masterModel.ProductsLevel_1.length) {
        if (this.masterModel.ProductsLevel_1.length > 0) {
          this.productsToAddLevel_1 = this.productsLevel_1.filter(
            (obj) => !this.masterModel.ProductsLevel_1.some((masterObj) => masterObj.ProductLevelOneID === obj.ID),
          )
        } else {
          this.productsToAddLevel_1 = this.productsLevel_1
        }
      } else {
        this.productsToAddLevel_1 = this.productsLevel_1
      }
      this.productsToAddLevel_1.forEach((prod) => {
        prod.selected = false
      })
    },
    getProductsToAddLevel_2() {
      var level_2_IDs = []
      if (this.masterModel.ProductsLevel_1) {
        if (this.masterModel.ProductsLevel_1.length > 0) {
          this.masterModel.ProductsLevel_1.forEach((level_1) => {
            if (level_1.ProductsLevel_2.length > 0) {
              level_1.ProductsLevel_2.forEach((level_2) => {
                level_2_IDs.push(level_2.ProductLevelTwoID)
              })
            }
          })
          this.productsToAddLevel_2 = this.configurationProductLevelStore.productsLevel_2.filter(
            (obj) => !level_2_IDs.includes(obj.ID),
          )
        } else {
          this.productsToAddLevel_2 = this.productsLevel_2
        }
      } else {
        this.productsToAddLevel_2 = this.productsLevel_2
      }
      this.productsToAddLevel_2.forEach((prod) => {
        prod.selected = false
      })
    },
    getProductsToAddLevel_3() {
      var level_3_IDs = []
      if (this.masterModel.ProductsLevel_1) {
        if (this.masterModel.ProductsLevel_1.length > 0) {
          this.masterModel.ProductsLevel_1.forEach((level_1) => {
            if (level_1.ProductsLevel_2.length > 0) {
              level_1.ProductsLevel_2.forEach((level_2) => {
                if (level_2.ProductsLevel_3.length > 0)
                  level_2.ProductsLevel_3.forEach((level_3) => {
                    level_3_IDs.push(level_3.ProductLevelThreeID)
                  })
              })
            }
          })
          this.productsToAddLevel_3 = this.configurationProductLevelStore.productsLevel_3.filter(
            (obj) => !level_3_IDs.includes(obj.ID),
          )
        } else {
          this.productsToAddLevel_3 = this.productsLevel_3
        }
      } else {
        this.productsToAddLevel_3 = this.productsLevel_3
      }
      this.productsToAddLevel_3.forEach((prod) => {
        prod.selected = false
      })
    },
  },
  computed: {
    filteredLevelOne() {
      return this.productsToAddLevel_1.filter((item) => {
        return item.Name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    filteredLevelTwo() {
      return this.productsToAddLevel_2.filter((item) => {
        return item.Name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    filteredLevelThree() {
      return this.productsToAddLevel_3.filter((item) => {
        return item.Name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    return { companyStore, userStore, configurationProductLevelStore }
  },
}
</script>

<style scoped lang="scss">
textarea {
  padding-left: 0 !important;
}
.upload-document {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}
.md-field {
  &.no-bg:after {
    background: transparent !important;
  }
}

.product-level-list {
  display: flex;
  flex: 0 0 100%;
  max-height: 500px;
  scroll-behavior: auto;
  overflow-y: auto;
}
.create-level-one-content {
  display: flex;
  justify-content: center;
}

.button-container {
  padding: 0 10px;
}
.pl-10 {
  padding-left: 10px;
}
.list-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.modal-list {
  max-height: 500px;
  overflow-y: auto;
  z-index: 1000;
}
.icon-container {
  cursor: pointer;
}
.animated-icon {
  visibility: hidden;
}
.list-row:hover {
  .animated-icon {
    visibility: visible;
  }
}
.center {
  display: flex;
  justify-content: center;
}

.modal-mask {
  z-index: 1050 !important;
}
.controls {
  display: flex;
  flex-direction: row;
}
// .tooltip {
//   visibility: hidden;
//   position: absolute;
//   width: auto;
//   max-width: 200px; /* Adjust as needed */
//   background-color: #333;
//   color: white;
//   padding: 5px;
//   border-radius: 5px;
//   z-index: 1000;
//   left: -0%;
//   top: -100%;
//   font-size: 13px;
//   text-align: center;
//   position: absolute;
//   transform: translateX(-50%);
//   opacity: 0;
//   transition: opacity 0.3s;
//   white-space: nowrap;
// }

// .icon-container:hover .tooltip {
//   visibility: visible;
//   opacity: 1;
// }
.title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.just-center {
  display: flex;
  justify-content: center !important;
}
.list-row {
  cursor: pointer;
}
.pt-15 {
  padding-top: 15px;
}
.description {
  cursor: default;
}
.md-card {
  margin-bottom: 0 !important;
}
.w-fc {
  width: fit-content;
}
</style>
