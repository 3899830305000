<template>
  <div>
    <div class="process-img-container md-layout-item md-size-66 md-small-size-100">
      <div class="img-row">
        <div class="img-col">
          <img v-if="selectedColor === 'black'" class="img" :src="'./../img/student_verification_process/1.png'" />
          <img v-else class="img" :src="'./../img/student_verification_process/1_grey.png'" />
        </div>
        <div class="img-col">
          <img v-if="selectedColor === 'black'" class="img" :src="'./../img/student_verification_process/arrow.png'" />
          <img v-else class="img" :src="'./../img/student_verification_process/arrow_grey.png'" />
        </div>
        <div class="img-col">
          <img v-if="selectedColor === 'black'" class="img" :src="'./../img/student_verification_process/2.png'" />
          <img v-else class="img" :src="'./../img/student_verification_process/2_grey.png'" />
        </div>
        <div class="img-col">
          <img v-if="selectedColor === 'black'" class="img" :src="'./../img/student_verification_process/arrow.png'" />
          <img v-else class="img" :src="'./../img/student_verification_process/arrow_grey.png'" />
        </div>
        <div class="img-col">
          <img v-if="selectedColor === 'black'" class="img" :src="'./../img/student_verification_process/3.png'" />
          <img v-else class="img" :src="'./../img/student_verification_process/3_grey.png'" />
        </div>
        <div class="img-col">
          <img v-if="selectedColor === 'black'" class="img" :src="'./../img/student_verification_process/arrow.png'" />
          <img v-else class="img" :src="'./../img/student_verification_process/arrow_grey.png'" />
        </div>
        <div class="img-col">
          <img v-if="selectedColor === 'black'" class="img" :src="'./../img/student_verification_process/4.png'" />
          <img v-else class="img" :src="'./../img/student_verification_process/4_grey.png'" />
        </div>
      </div>
      <div class="img-row img-col-names">
        <div class="img-col">{{ infoColName[langChange][0] }}</div>
        <div class="img-col"></div>
        <div class="img-col">{{ infoColName[langChange][1] }}</div>
        <div class="img-col"></div>
        <div class="img-col">{{ infoColName[langChange][2] }}</div>
        <div class="img-col"></div>
        <div class="img-col">{{ infoColName[langChange][3] }}</div>
      </div>
    </div>

    <div class="md-layout-item md-size-66 mx-auto md-medium-size-100 grey-bg" :key="langChange">
      <!--disabled tab panes: , 'policy', 'compare'  -->
      <tabs :tab-name="tabName[langChange]" :tab-icon="['key', 'file_download']" class="page-subcategories"
        nav-pills-icons plain color-button="primary">
        <h3 class="title text-center" slot="header-title">
          {{ productName }}
        </h3>
        <template slot="tab-pane-1">
          <loader :loader="loader" />

          <div class="tiles-sturcture">
            <div class="alert alert-info alert-with-icon warning-message" data-notify="container"
              v-if="this.verificationStatus === 1 || !this.verificationStatus">
              <i data-notify="icon" class="material-icons">add_alert</i>
              <span data-notify="message">{{ $t('warningMassageOnStudentProduct') }}</span>
            </div>
            <div class="timeline-ima">
              <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50">
                <time-line plain type="simple">
                  <time-line-item inverted badge-type="primary" badge-icon="contact_page"
                    :class="{ ['badge-in-progress']: confirmStudentEmail }">
                    <span slot="header" class="badge badge-primary">{{ $t('documents') }}</span>
                    <div slot="content">
                      <p :key="langChange">
                        {{ eductionProofText }}
                      </p>
                      <div v-if="[3, 4, 6].includes(this.verificationStatus)">
                        <div class="uploaded-ima-line"></div>
                        <p class="uploaded-ima" v-for="(item, index) in imaFilesData" :key="index">{{ item.Name }}</p>
                        <p v-if="studentEmailTimeline">{{ studentEmailTimeline }}</p>
                      </div>
                    </div>
                  </time-line-item>
                  <time-line-item inverted :badge-type="badgeVerificationInProcess" badge-icon="sync"
                    :class="{ ['badge-in-progress']: verificationInProcess && verificationStatus != 3 }">
                    <span slot="header" :class="{
                      ['badge badge-primary']: verificationInProcess,
                      ['badge badge-default']: !verificationInProcess,
                    }">{{ $t('verification') }}</span>
                    <div slot="content">
                      <p>
                        {{ reviewImaText }}
                      </p>
                      <div v-if="[3].includes(this.verificationStatus)">
                        <div class="uploaded-ima-line"></div>
                        <p>{{ $t('trainingEntity') }}: {{ uniName }}</p>
                      </div>
                    </div>
                  </time-line-item>
                  <time-line-item inverted :badge-type="badgeVerificationApproved" badge-icon="shopping_cart"
                    :class="{ ['badge-in-progress']: verificationApproved && !paymentSuccess }">
                    <span slot="header" :class="{
                      ['badge badge-primary']: verificationApproved,
                      ['badge badge-default']: !verificationApproved,
                    }">{{ $t('productPurchase') }}</span>
                    <div slot="content" v-if="!paymentSuccess">
                      <p>
                        {{ $t('productPurchaseText') }}
                      </p>
                    </div>
                    <div slot="content" v-if="paymentSuccess">
                      <p>
                        {{ $t('productPurchaseSuccess') }}
                      </p>
                    </div>
                  </time-line-item>
                  <time-line-item inverted :badge-type="badgePaymentSuccess" badge-icon="done">
                    <span slot="header" :class="{
                      ['badge badge-primary']: titlePaymentSuccess,
                      ['badge badge-default']: !titlePaymentSuccess,
                    }">{{ $t('licenceDownloadTitle') }}</span>
                    <div slot="content">
                      <p>
                        {{ productName + ' ' + $t('downloadLicense') }}
                      </p>
                    </div>
                  </time-line-item>
                </time-line>
              </div>
              <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50">
                <md-card class="ima-tile" v-if="!verificationApproved">
                  <md-card-header>
                    <h4 class="title">{{ $t('proveEducationTitle') }}</h4>
                    <p class="category">{{ productName }}</p>
                  </md-card-header>
                  <md-card-content class="content-style">
                    <div class="eduction-proof" v-if="!confirmStudentEmail && !studentEmailIsInReview">
                      {{ $t('provideEducationProof') }}
                    </div>
                    <div class="eduction-proof" v-if="confirmStudentEmail">
                      {{ $t('confirmStudentEmail') }}
                    </div>
                    <div v-if="studentEmailIsInReview">{{ $t('studentEmailIsInReview') }}</div>
                    <div class="student-email">
                      <ValidationObserver v-slot="{ invalid }">
                        <div v-if="!imaNotReadable">
                          <ValidationProvider name="email" :rules="{ required: true, email: true, customEmail: true }"
                            v-slot="{ passed, failed }">
                            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]" required>
                              <label class="uni-email-label">{{ $t('universityEmail') }}</label>
                              <md-input v-model="studentEmail" type="email" class="uni-email-input"> </md-input>
                              <slide-y-down-transition>
                                <md-icon class="error" v-show="failed">close</md-icon>
                              </slide-y-down-transition>
                              <slide-y-down-transition>
                                <md-icon class="success" v-show="passed">done</md-icon>
                              </slide-y-down-transition>
                            </md-field>
                          </ValidationProvider>

                          <md-button slot="footer" class="md-primary md-round uni-email-button button-pointer"
                            :disabled="invalid" id="uniEmail" @click="verifyStudentEmail">
                            {{ $t('uniEmailButton') }}
                          </md-button>
                        </div>
                      </ValidationObserver>
                      <div v-if="imaNotReadable">{{ $t('imaNotReadable') }}</div>
                      <div>
                        <md-checkbox v-model="noUniEmail" class="ima-checkbox" v-if="!imaNotReadable">{{
                          $t('noUniEmail')
                        }}</md-checkbox>
                        <div v-if="noUniEmail">
                          <h4 class="upload-document-title">
                            {{ $t('uploadProofEduction') }}
                          </h4>
                          <form enctype="multipart/form-data">
                            <div class="md-layout-item upload-document">
                              <div class="file-input">
                                <div v-if="!imageRegular">
                                  <div class="image-container">
                                    <md-button class="button-pointer md-fileinput ima-submit">
                                      <img :src="regularImg" title="" />
                                      <input type="file" @change="onFileChange" @click="onInputClick" />
                                    </md-button>
                                  </div>
                                </div>
                                <div class="image-container" v-else>
                                  <md-button class="button-pointer md-fileinput ima-submit">
                                    <img :src="imageRegular" />
                                    <input type="file" @change="onFileChange" @click="onInputClick" />
                                  </md-button>
                                </div>
                                <div v-if="pdfName && imageRegular" class="pdf-name">
                                  {{ pdfName }}
                                </div>
                                <div class="button-container">
                                  <div class="remove-change button-pointer">
                                    <md-button class="md-primary md-round md-fileinput ima-submit" v-if="imageRegular"
                                      @click="uploadEducationProof(productID)">{{ $t('upload') }}
                                    </md-button>
                                    <md-button :class="[
                                      { 'md-simple': !imageRegular },
                                      'md-fileinput',
                                      'md-round',
                                      'button-pointer',
                                      'ima-change',
                                      { 'md-simple': imageRegular },
                                    ]">
                                      <template v-if="!imageRegular">{{ $t('selectImg') }}</template>

                                      <template v-else>{{ $t('change') }}</template>
                                      <input type="file" @change="onFileChange" @click="onInputClick" />
                                    </md-button>
                                    <md-button class="md-simple md-round button-pointer ima-delete" @click="removeImage"
                                      v-if="imageRegular">{{ $t('remove')
                                      }}
                                    </md-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </md-card-content>
                </md-card>
                <md-card class="ima-tile" v-if="verificationApproved">
                  <md-card-header>
                    <h4 class="title" v-if="verificationApproved && paymentSuccess">{{ $t('congratulations') }}</h4>
                    <h4 class="title" v-if="verificationApproved && !paymentSuccess">{{ $t('paymentProcess') }}</h4>
                    <p class="category">{{ productName }}</p>
                  </md-card-header>
                  <md-card-content class="content-style approved-status">
                    <div v-if="testOrder">{{ $t('paymentFromTestAccount') }}</div>
                    <div v-if="verificationApproved && paymentSuccess && !paymentCookie">
                      {{ $t('requestLicenseAllowed') }}
                    </div>
                    <div v-if="verificationApproved && !paymentSuccess && paymentCookie">
                      {{ $t('paymentIsProccessed') }}
                    </div>
                    <div v-if="verificationApproved && !paymentSuccess && !paymentCookie">
                      <div>{{ $t('makePaymentText') }}</div>
                      <div>
                        <a :href="paymentLink" class="payment-link" target="_blank">
                          <md-button :class="['md-primary md-round']" v-if="verificationApproved">{{
                            $t('paymentLink')
                          }}</md-button></a>
                      </div>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
            </div>
            <div v-if="imaFileNames.length" class="ima-docs">
              <md-card>
                <md-card-header>
                  <h4 class="title-documents-download">
                    {{ $t('downloadima') }}
                  </h4>
                </md-card-header>
                <div v-for="(item, index) in imaFilesData" :key="index" class="download-ima-section">
                  <div class="download-ima-name" @click="downloadIma(imaFilesData[index].ImaFile, item.Name)">
                    {{ item.Name }}
                  </div>
                  <div class="ima-actions">
                    <div class="download-delete-ima" @click="downloadIma(imaFilesData[index].ImaFile, item.Name)">
                      <md-icon @click="downloadIma(imaFilesData[index].ImaFile, item.Name)"
                        class="download-icon">file_download</md-icon>
                    </div>
                    <div class="download-delete-ima" @click="deleteIma(item.DataFileID)" v-if="!verificationApproved">
                      <md-icon class="delete-icon">delete_outline</md-icon>
                    </div>
                  </div>
                </div>
              </md-card>
            </div>
          </div>
        </template>

        <template slot="tab-pane-2" v-if="!testOrder">
          <loader :loader="loader" />
          <md-card>
            <md-card-header>
              <div v-if="verificationApproved && licenceIsValid && paymentSuccess" class="licence-and-links">
                <div class="licence-link">
                  <h4 class="title2">{{ $t('program') + ' ' + productName }}</h4>
                  <a id="download-link" class="download-button"
                    href="https://download.mdesign.de/md2024/240301/173904/stud" target="_blank">
                    <md-button :class="['md-primary md-round rings']">
                      {{ $t('download') }}
                    </md-button>
                  </a>
                </div>
                <div class="licence-link">
                  <h4 class="title2">
                    {{ $t('installationGuide') }}
                  </h4>
                  <a id="download-link" class="download-button" :href="$t('installationGuideLink')" target="_blank">
                    <md-button :class="['md-primary md-round rings']">
                      {{ $t('open') }}
                    </md-button>
                  </a>
                </div>
                <div class="licence-link">
                  <h4 class="title2">
                    {{ $t('licenseFile') }}
                  </h4>
                  <a id="download-link" class="download-button" @click="downloadLicence('Studienversion 2024')">
                    <md-button :class="['md-primary md-round rings']">
                      {{ $t('download') }}
                    </md-button>
                  </a>
                </div>
              </div>
              <h4 class="title2" v-if="!verificationApproved">
                {{ $t('provideStudentInfo') }}
              </h4>
              <h4 class="title2" v-if="verificationApproved && licenceIsValid && !paymentSuccess && !paymentCookie">
                {{ $t('makePaymentText') }}
              </h4>
              <h4 class="title2" v-if="verificationApproved && licenceIsValid && !paymentSuccess && paymentCookie">
                {{ $t('paymentIsProccessed') }}
              </h4>
              <h4 class="title2" v-if="!licenceIsValid">{{ $t('​licenceIsExpired') }}</h4>
            </md-card-header>
          </md-card>
        </template>
      </tabs>
    </div>
  </div>
</template>

<script>
import { Tabs, TimeLine, TimeLineItem } from '@/components'
import { useUserStore } from '@/stores/user.js'
import { useCompanyStore } from '@/stores/company'
import Swal from 'sweetalert2'
import image_placeholder from '../../../../public/img/image_placeholder.jpg'
import pdfImg from '../../../../public/img/PDF_file_icon.svg.png'
import { sendEmailApproveStudentImo, sendEmailConfirmStudentEmail } from '@/libs/emailSender.js'
//disabled tab panes: import StudentVersionComparisonTable from './StudentVersionsComparisonTable.vue'
import { httpGetShopUrl } from '@/libs/shopUrlGeneration.js'
import { reinitScrollbar } from '../../../pages/UserLayout/DashboardLayout.vue'
import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import { i18n } from '@/main'
import { permissionMixin } from '@/pages/RoleManagement/permissionMixin'

function customEmailValidator(value, i18n) {
  // Check if the email doesn't end with 'gmail.com' and othe domains
  const blacklist = [
    'web.de',
    'gmx.de',
    'gmx.net',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'outlook.de',
    'icloud.com',
    'me.com',
    'mac.com',
    'msn.com',
    'gmail.com',
    'aol.com',
    'love.com',
    'ygm.com',
    'games.com',
    'wow.com',
    'proton.me',
    'proton.com',
    'freenet.de',
    'freenet.com',
  ]
  const emailDomain = value.split('@')[1]
  var errorMessage = document.createElement('div')
  errorMessage.className = 'custom-email-error'
  if (blacklist.includes(emailDomain)) {
    var existingErrorMessages = document.querySelectorAll('.custom-email-error')
    existingErrorMessages.forEach(function (errorMsg) {
      errorMsg.remove()
    })
    document.querySelectorAll('.uni-email-button').forEach(function (element) {
      errorMessage.textContent = i18n.t('emailCannotIncludeThisDomain')
      element.parentNode.insertBefore(errorMessage, element)
    })
    return false
  } else {
    var existingErrorMessages = document.querySelectorAll('.custom-email-error')
    existingErrorMessages.forEach(function (errorMsg) {
      errorMsg.remove()
    })
    return true
  }
}
extend('required', required)
extend('email', email)
extend('customEmail', {
  validate: (value) => {
    return customEmailValidator(value, i18n)
  },
})

export default {
  mixins: [permissionMixin],
  components: {
    Tabs,
    TimeLine,
    TimeLineItem,
    //disabled tab panes: StudentVersionComparisonTable,
  },
  data() {
    return {
      pdf: pdfImg,
      pdfName: '',
      pngName: '',
      langChange: '',
      productID: '{cc20e362-7c96-4c88-b14d-4ff97bd2864b}',
      imageRegular: '',
      componentKey: 0,
      tabName: [
        ['Verifizierung', 'Download'], //disabled tab panes: , 'Bedingungen für die Nutzung', 'Vergleich der Versionen'
        ['Verification', 'Download'], //, 'Terms of use', 'Comparison of versions'
      ],
      infoColName: [
        ['Registrieren', 'Verifizieren', 'Bezahlen', 'Download'],
        ['Registration', 'Verify', 'Pay', 'Download'],
      ],
      noUniEmail: false,
      studentEmail: '',
      files: [],
      loader: false,
      verificationInProcess: false,
      verificationApproved: false,
      badgeVerificationInProcess: 'disabled',
      badgePaymentSuccess: 'disabled',
      titlePaymentSuccess: false,
      badgeVerificationApproved: 'disabled',
      regularImg: image_placeholder,
      downloadFiles: [],
      verificationID: '',
      imaFileNames: [],
      dataFileIDs: [],
      imaFilesData: [],
      imaDataNumber: '',
      verificationStatus: '', //- 1 ⇒ Please provide proof of status, 2 ⇒ Please click confirmation link, 3 ⇒ OK (+name of university), 4 ⇒ in progress (university is being examined), 5 ⇒ Reference to IMA usage, 6 ⇒ in progress (IMA is being checked),7 ⇒ IMA not readable
      confirmStudentEmail: true,
      imaNotReadable: false,
      studentEmailIsInReview: false,
      licenceFileID: '',
      studentDomainID: '',
      licenceIsValid: true,
      paymentLink: '',
      paymentSuccess: false,
      eductionProofText: '',
      renderKey: 0,
      studentEmailTimeline: '',
      reviewImaText: '',
      statusProofInProgress: false,
      productDetails: null,
      productName: '',
      selectedColor: document.body.getAttribute('data-background-color'),
      contactConsents: null,
      consentReturn: false,
      testOrder: false,
      paymentCookie: false,
    }
  },
  methods: {
    onInputClick(e) {
      e.target.value = null
    },
    onFileChange(e) {
      this.file = e.target.files || e.dataTransfer.files
      if (!this.file.length) return
      this.createImage(this.file[0])
    },
    removeImage: function () {
      this.imageRegular = ''
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      if (file.type.match('\pdf')) {
        reader.onload = () => {
          this.imageRegular = this.pdf
        }
        reader.readAsDataURL(file)
        this.pdfName = file.name
        return
      }

      reader.onload = (e) => {
        vm.imageRegular = e.target.result
      }
      reader.readAsDataURL(file)

      this.pdfName = ''
    },
    async uploadEducationProof(ProductID) {
      if (!this.checkProfileData()) {
        return
      }

      this.loader = true
      const result = await this.userStore.uploadEducationProof(this.file[0], ProductID)
      if (result?.status === 200) {
        await this.getVerificationStatus(ProductID)
        await this.getImaFileNames()
        await this.getImaFile()

        const emailSent = await sendEmailApproveStudentImo(
          this.userStore.userData.Email,
          this.file[0],
          this.file[0].name,
        )
        this.loader = false
        if (!emailSent) {
          Swal.fire({
            icon: 'error',
            text: this.$t('uploadOtherImaFormat'),
          })
          return
        }
        Swal.fire({
          icon: 'info',
          text: this.$t('successEducationProofUpload'),
        })
      } else if (result === 'maximum file number reached') {
        this.loader = false
        Swal.fire({
          icon: 'error',
          text: this.$t('maximumNumberOfIma'),
        })
      } else if (result === 'this file is not an image or pdf') {
        this.loader = false
        Swal.fire({
          icon: 'error',
          text: this.$t('uploadOtherImaFormat'),
        })
      } else {
        this.loader = false
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: this.$t('profileNotUpdated'),
        })
      }
      return result
    },
    async getVerificationStatus(ProductID) {
      const verificationObject = await this.userStore.getUserVerification(ProductID)
      if (verificationObject?.length) {
        this.verificationStatus = verificationObject[0].Status
        this.studentEmailTimeline = verificationObject[0].StudentEmail
        this.eductionProofText = [3, 4, 6].includes(this.verificationStatus)
          ? this.$t('uploadedEducationProof')
          : this.$t('toUploadEducationProof')
        this.reviewImaText = [3].includes(this.verificationStatus)
          ? this.$t('verificationProcessFinished')
          : this.$t('verificationProcess')
        this.uniName = this.langChange ? verificationObject[0].UniversityNameEN : verificationObject[0].UniversityNameDE
        this.studentDomainID = verificationObject[0].StudentDomainID
        this.verificationID = verificationObject[0].ID
        this.imaDataNumber = verificationObject[0].ImaData
        this.licenceIsValid = verificationObject[0].ValidityEnd > new Date().toISOString()
        if (this.verificationStatus === 2 || this.verificationStatus === 1) {
          this.confirmStudentEmail = true
        }
        if (this.verificationStatus === 6 || this.verificationStatus === 4) {
          this.confirmStudentEmail = false
          this.verificationInProcess = true
          this.badgeVerificationInProcess = 'primary'
        }
        if (this.verificationStatus === 4) {
          this.studentEmailIsInReview = true
        }
        if (this.verificationStatus === 3) {
          this.verificationInProcess = true
          this.badgeVerificationInProcess = 'primary'
          this.verificationApproved = true
          this.badgeVerificationApproved = 'primary'
          this.confirmStudentEmail = false
        }
        if (this.verificationStatus === 3 && this.paymentSuccess && !this.testOrder) {
          this.verificationInProcess = true
          this.badgeVerificationInProcess = 'primary'
          this.verificationApproved = true
          this.badgeVerificationApproved = 'primary'
          this.badgePaymentSuccess = 'primary'
          this.titlePaymentSuccess = true
          this.confirmStudentEmail = false
        }
        //for test order
        if (this.verificationStatus === 3 && this.paymentSuccess && this.testOrder) {
          this.verificationInProcess = true
          this.badgeVerificationInProcess = 'primary'
          this.verificationApproved = true
          this.badgeVerificationApproved = 'primary'
          this.paymentSuccess = false

        }
        if (this.verificationStatus === 7) {
          // this.verificationInProcess = true
          // this.badgeVerificationInProcess = 'primary'
          // this.imaNotReadable = true
          // this.noUniEmail = true
          this.confirmStudentEmail = true
        }
      }
    },
    async getImaFile() {
      if (!this.verificationID) {
        return
      }
      if (!this.dataFileIDs) {
        return
      }
      for (let index = 0; index < this.imaFilesData.length; index++) {
        const responseDownloadImaFile = await this.userStore.getDownloadFile(this.imaFilesData[index].DataFileID)
        this.imaFilesData[index] = {
          ...this.imaFilesData[index],
          ImaFile: responseDownloadImaFile.data,
        }
      }
    },
    async getImaFileNames() {
      this.dataFileIDs = []
      this.imaFileNames = []
      this.imaFilesData = []
      if (!this.verificationID) {
        return
      }
      const responseGetFileRefByTable = await this.userStore.getFileReferenceByTable(
        this.verificationID,
        'verification',
      )

      if (!responseGetFileRefByTable) {
        return
      }
      responseGetFileRefByTable.data.forEach((element) => {
        this.dataFileIDs.push(element.DataFileID)
      })
      for (const element of this.dataFileIDs) {
        const response = await this.userStore.getDataFileDetails(element)
        //sort ima and license files
        if (response.data[0].Filetype.includes('lic')) {
          this.licenceFileID = response.data[0].ID
        } else {
          this.imaFileNames.push(response.data[0].Name)
          this.imaFilesData.push({
            DataFileID: element,
            Name: response.data[0].Name,
          })
        }
      }
    },
    async downloadIma(File, Name) {
      let file
      const reader = new FileReader()
      reader.onload = () => {
        file = reader.result
        const linkSource = file
        const downloadLink = document.createElement('a')
        downloadLink.href = linkSource
        downloadLink.download = Name
        downloadLink.click()
      }
      reader.readAsDataURL(File)
    },
    checkProfileData() {
      if (!this.userStore.userData.Salutation) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: this.$t('fillProfileData'),
        }).then((result) => {
          this.$router.push({ name: 'User' })
        })
        return false
      }
      return true
    },
    async verifyStudentEmail() {
      if (!this.checkProfileData()) {
        return
      }

      this.loader = true
      const verificationCreated = await this.userStore.createVerificationByEmail(this.productID, this.studentEmail)
      if (verificationCreated.status === 200) {
        await this.getVerificationStatus(this.productID)
        const responseVerifyEmail = await sendEmailConfirmStudentEmail(
          this.userStore.token,
          this.studentEmail,
          this.userStore.userData.FirstName,
          this.userStore.userData.LastName,
        )
        this.loader = false
        if (responseVerifyEmail === 'Email is sended.') {
          Swal.fire({
            icon: 'info',
            title: this.$t('success'),
            html: this.$t('emailSent'),
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: this.$t('error'),
          })
        }
      } else if (verificationCreated.status === 400) {
        this.loader = false
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: this.$t('studentEmailVerificationFail'),
        })
      } else {
        this.loader = false
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: this.$t('setNewPassErrorPopup'),
        })
      }
    },
    async downloadLicence(templateName) {
      if (!this.consentReturn && this.productID === '{cc20e362-7c96-4c88-b14d-4ff97bd2864b}') {
        var consent = await Swal.fire({
          icon: 'warning',
          title: this.$t('consentReturn'),
          html: this.$t('consentReturnText'),
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
        }).then((result) => {
          return result
        })
        if (!consent.isConfirmed) {
          return
        }
        const now = this.companyStore.getNow()
        var objConsent = {
          ContactID: this.userStore.userData.ID,
          ConsentTypeID: '{df4f4056-590a-4736-ade0-8c14377d461e}',
          Consent: true,
          ConsentDate: now,
        }
        var resCons = await this.userStore.createContactHasConsentType(objConsent, this.userStore.token)
        if (resCons.status !== 200) {
          Swal.fire({
            icon: 'error',
            title: this.$t('error'),
            html: this.$t('errorConsentDownload'),
          })
          return
        }
        this.consentReturn = true
      }

      this.loader = true
      let result
      if (this.licenceFileID) {
        const changeDownloadCount = true
        result = await this.userStore.getDownloadFile(this.licenceFileID, changeDownloadCount)
      } else {
        result = await this.userStore.generateStudentLicense(
          this.userStore.userData.FirstName,
          this.userStore.userData.LastName,
          this.userStore.userData.Email,
          this.userStore.userData.ID,
          this.verificationID,
          templateName,
        )
        this.licenceFileID = result.dataObj.id
        await this.updateValidityStartDate()
      }
      if (result.status === 200) {
        this.loader = false
        const blobData = result.data
        // Create a blob URL
        const blobUrl = URL.createObjectURL(blobData)
        // Create an anchor element
        const link = document.createElement('a')
        // Set the href attribute to the blob URL
        link.href = blobUrl
        // Specify the download attribute and the desired file name
        link.download = 'mdesign_general_2024.lic' // Replace 'yourFileName.ext' with the desired file name
        // Append the link to the body (you can also append it to another element)
        document.body.appendChild(link)
        // Trigger a click on the link to start the download
        link.click()
        // Remove the link from the DOM
        document.body.removeChild(link)
        // Revoke the blob URL to free up resources
        URL.revokeObjectURL(blobUrl)
        // this.userStore.updateDownloadCount()
      } else {
        this.loader = false
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: this.$t('setNewPassErrorPopup'),
        })
      }
    },
    async deleteIma(dataFileID) {
      Swal.fire({
        title: this.$t('deleteTitle'),
        text: this.$t('deleteText'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'md-button md-success btn-fill',
        cancelButtonClass: 'md-button md-danger btn-fill',
        confirmButtonText: this.$t('confirmDelete'),
        cancelButtonText: this.$t('cancelDelete'),
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const resultOfResponce = await this.userStore.deleteImaFile(
            dataFileID,
            this.verificationID,
            this.imaDataNumber - 1,
          )
          if (resultOfResponce) {
            reinitScrollbar()
            Swal.fire({
              title: this.$t('deletedRowText'),
              text: this.$t('deletedImaText'),
              type: 'success',
              confirmButtonClass: 'md-button md-success btn-fill',
              buttonsStyling: false,
              icon: 'success',
            })
            this.imageRegular = ''
            await this.getImaFileNames()
            await this.getImaFile()
            this.imaDataNumber = this.imaDataNumber - 1
          } else {
            Swal.fire({
              title: this.$t('error'),
              text: this.$t('setNewPassErrorPopup'),
              type: 'error',
              confirmButtonClass: 'md-button md-error btn-fill',
              buttonsStyling: false,
              icon: 'error',
            })
          }
        }
      })
    },
    async checkEmailDomen(productID) {
      const email = this.userStore.userData.Email
      const emailDomen = email.split('@')[1]
      const response = await this.userStore.getStudentDomainByEmailDomain(emailDomen)
      if (response.status === 200) {
        const domainID = response.data[0].ID
        // Swal.fire({
        //   icon: 'info',
        //   title: this.$t('dearStudent'),
        //   html: this.$t('useEmailasStudentEmail'),
        //   showCancelButton: true,
        //   confirmButtonClass: 'md-button md-success btn-fill',
        //   cancelButtonClass: 'md-button md-danger btn-fill',
        //   confirmButtonText: this.$t('yes'),
        //   cancelButtonText: this.$t('no'),
        // }).then(async (result) => {
        let createVerificationResponse
        if (!this.verificationStatus) {
          createVerificationResponse = await this.userStore.createVerificationForExistedStudentDomain(
            productID,
            this.userStore.userData.ID,
            this.userStore.userData.Email,
          )
          //in case email was already used for other verification - verification create without email
          if (createVerificationResponse?.status != 200) {
            createVerificationResponse = await this.userStore.createVerificationForExistedStudentDomain(
              productID,
              this.userStore.userData.ID,
            )
          }
        }
        if (createVerificationResponse?.status === 200 || this.verificationStatus) {
          const verificationID = createVerificationResponse ? createVerificationResponse.data : this.verificationID
          await this.userStore.changeStatusOfVerification(
            productID,
            this.userStore.userData.ID,
            3,
            verificationID,
            true,
            domainID,
          )
          await this.getVerificationStatus(productID)
        }
        this.loader = false
      }
    },
    async updateValidityStartDate() {
      const startDate = new Date().toISOString()
      await this.userStore.updateValidityStartDate(
        this.verificationID,
        this.userStore.userData.ID,
        this.productID,
        this.studentDomainID,
        startDate,
      )
    },
    async getPaymentStatus() {
      const contactID = this.userStore.userData.ID
      const response = await this.userStore.getOrderingByContact(contactID)
      let testOrderArray = []
      if (response.status === 200) {
        response.data.forEach((element) => {
          if (element.ProductID === this.productID) {
            this.paymentSuccess = true
            testOrderArray.push(element.Testorder)
          }
        })
        if (testOrderArray.length) {
          testOrderArray.includes(false) ? this.testOrder = false : this.testOrder = true
        }
      }
    },
    async getProductDetails(productID) {
      const response = await this.userStore.getProductDetailsByID(productID)
      if (response.status === 200) {
        this.productDetails = response.data[0]
      }
    },
    observeBodyAttribute() {
      const body = document.body
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'data-background-color') {
            this.selectedColor = body.getAttribute('data-background-color')
          }
        })
      })

      observer.observe(body, {
        attributes: true,
      })
    },
    async getContactConsentTypes() {
      var resUserConsents = await this.userStore.getContactHasConsentTypeByContactID(
        this.userStore.userData.ID,
        this.userStore.token,
      )
      if (resUserConsents.status === 200) {
        this.contactConsents = resUserConsents.data
        var returnConsent = this.contactConsents.find(
          (obj) => obj.ConsentTypeID === '{df4f4056-590a-4736-ade0-8c14377d461e}',
        )
        if (returnConsent) {
          this.consentReturn = returnConsent.Consent
        }
      }
    },
    async clickDownloadTab() {
      this.loader = true
      await this.getPaymentStatus()
      await this.getVerificationStatus(this.productID)
      this.loader = false
    },
    setCookieWaitingForPayment(name, value, minutes) {
      const date = new Date();
      // Set the time to 15 minutes in the future
      date.setTime(date.getTime() + (minutes * 60 * 1000));
      const expires = "expires=" + date.toUTCString();
      document.cookie = `${name}=${value}; ${expires}; path=/`;
      this.paymentCookie = true
    },
    getCookieByName(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c;
      }
      return null;
    }
  },
  setup() {
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    return { userStore, companyStore }
  },
  async mounted() {
    this.$changePageTitle(this.$t('pageTitleStudentVersion24'))
    if (!this.userStore.userProductsID.includes('{cc20e362-7c96-4c88-b14d-4ff97bd2864b}'))
      this.$router.push({ name: 'My Products' })
    this.loader = true
    await this.getPaymentStatus()
    await this.getVerificationStatus(this.productID)
    const currentPageUrl = window.location.href
    //to open second tab after redirect from payment
    if (currentPageUrl.includes('return-type=redirect')
      && currentPageUrl.includes('prod=MD000072')
      && currentPageUrl.includes('refno=')) {
      const urlWithoutQuery = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, urlWithoutQuery);
      const liElements = document.querySelectorAll('.md-list-item')
      if (liElements.length >= 2) {
        const secondLiElement = liElements[1]
        secondLiElement.click()
      }
      if (!this.getCookieByName("2checkoutPayment") && !this.paymentSuccess) {
        this.setCookieWaitingForPayment("2checkoutPayment", true, 15)
      }
    }
    if (this.getCookieByName("2checkoutPayment") && !this.paymentSuccess) {
      this.paymentCookie = true
    }

    if (!this.verificationStatus || this.verificationStatus === 2 || this.verificationStatus === 1) {
      await this.checkEmailDomen(this.productID)
    }
    await this.getImaFileNames()
    await this.getImaFile()
    const email = this.userStore.userData.Email
    const companyID = this.userStore.userData.CompanyID === 'NULL' ? '' : this.userStore.userData.CompanyID
    const contactID = this.userStore.userData.ID
    const token = this.userStore.token
    if (this.verificationApproved) {
      const shoppingUrlResponse = await httpGetShopUrl(companyID, this.productID, contactID, email, token)
      this.paymentLink = shoppingUrlResponse.data
    }
    await this.getProductDetails(this.productID)
    this.productName = this.langChange ? this.productDetails?.TitleEN : this.productDetails?.Title

    await this.getContactConsentTypes()

    this.observeBodyAttribute()
    this.loader = false
    //track click on downloads tab
    const tabsList = document.querySelectorAll('.nav-tabs')
    tabsList[0].children[1].addEventListener('click', this.clickDownloadTab)
  },
  beforeMount() {
    if (this.$i18n.locale === 'de-DE') {
      this.langChange = 0
    } else {
      this.langChange = 1
    }
  },
  updated() {
    this.eductionProofText = [3, 4, 6].includes(this.verificationStatus)
      ? this.$t('uploadedEducationProof')
      : this.$t('toUploadEducationProof')
    this.reviewImaText = [3].includes(this.verificationStatus)
      ? this.$t('verificationProcessFinished')
      : this.$t('verificationProcess')

    if (this.$i18n.locale === 'de-DE') {
      this.langChange = 0
      this.productName = '' || this.productDetails?.Title
    } else {
      this.langChange = 1
      this.productName = '' || this.productDetails?.TitleEN
    }
  },
}
</script>

<style scoped>
/deep/.tab-content {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 576px) {
  /deep/.tab-pane-2 {
    min-width: 600px;
  }
}

/deep/.content-style {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.uni-email-label {
  text-wrap: nowrap;
}

@media (max-width: 330px) {
  .uni-email-label {
    max-width: 190px;
  }

  .uni-email-input {
    margin-bottom: 10px;
  }

  .uni-email-input:focus {
    margin-bottom: 0px;
  }
}

#uniEmail {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.upload-document {
  display: flex;
  justify-content: center;
}

.remove-change {
  display: flex;
}

@media (max-width: 420px) {
  .remove-change {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  /deep/.tab-pane-1 {
    flex-direction: column !important;
  }

  /deep/.md-layout-item {
    padding: 0 !important;
  }

  .img-col-names {
    display: none !important;
  }

  .process-img-container {
    padding: 0 20px !important;
  }
}

.image-container {
  margin: 0 auto;
}

.pdf-name {
  display: flex;
  justify-content: center;
}

.button-pointer {
  cursor: pointer;
}

input[type='file']::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.progress-bar>button {
  display: flex;
  width: 32%;
}

/deep/.tab-pane-1 {
  display: flex !important;
}

.md-card-plain {
  margin: 0;
}

/deep/.ima-checkbox>label {
  height: auto !important;
}

/deep/.ima-checkbox {
  margin: 16px 16px 0 0 !important;
}

.md-card {
  padding: 15px 20px;
}

.tiles-structure {
  display: flex;
  flex-direction: column;
}

.timeline-ima {
  display: flex;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.ima-tile {
  margin-top: 45px;

  @media screen and (max-width: 600px) {
    margin-top: 30px;
  }
}

.title-documents-download {
  display: flex;
  justify-content: center;
  margin: 0;
}

.download-ima-section {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.download-icon,
.delete-icon {
  margin: 10px;
}

.download-delete-ima,
.download-ima-name {
  cursor: pointer;
}

.ima-actions {
  display: flex;
  justify-content: space-between;
}

.image-container button {
  width: 250px;
  padding: 0;
  margin: 0;
  display: flex;
}

.image-container /deep/.md-ripple {
  padding: 0 !important;
  background-color: white !important;
}

.approved-status {
  padding: 15px 0;
}

.download-button,
.payment-link {
  display: flex;
  justify-content: center;
}

.payment-link {
  padding-top: 15px;
}

.button-container {
  margin-top: 10px;
}

div.ima-docs {
  margin-right: 15px;
  margin-left: 55px;
}

/deep/.timeline-badge>i {
  padding-bottom: 5px !important;
}

/deep/.timeline:before {
  height: 75%;
}

.warning-message {
  margin: 0 15px 0 75px;
}

.licence-link {
  display: flex;
  justify-content: space-between;
}

@keyframes ring {
  0% {
    width: 20px;
    height: 20px;
    opacity: 1;
  }

  100% {
    width: 300px;
    height: 300px;
    opacity: 1;
  }
}

.rings::after {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #81c2de;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite !important;
}

.rings {
  height: 30px;
  display: flex;
  align-self: center;
  width: 95px;
}

.rings:hover::after,
button:focus::after {
  animation: none;
  display: none;
}

.download-button {
  margin-left: 15px;
}

.uploaded-ima-line {
  height: 1px;
  background-color: rgb(202, 202, 202) !important;
  width: 100%;
}

/deep/.custom-email-error {
  color: #f44336 !important;
  margin-top: 5px;
}

.badge-in-progress {
  .timeline-badge {
    background-color: white !important;
    border: #009ee3 2px solid !important;
  }

  i.md-icon.md-icon-font.md-theme-default {
    color: #009ee3 !important;
  }
}

[data-background-color='black'] .badge-in-progress {
  .timeline-badge {
    background-color: #3c4858 !important;
  }
}

.process-img-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 800px;
  justify-content: center;
  min-width: none !important;
}

body .main-panel>.content {
  background-color: #f8f9fb !important;
}

[data-background-color='white'] .grey-bg {
  background-color: rgb(220, 220, 220);
}

.img-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.img-col {
  display: flex;
  color: rgb(202, 202, 202);
  justify-content: center;
  flex: 0 0 14.28%;
}

.img-col-names {
  padding: 0 5px;
  margin-top: 20px;
}
</style>
