<template>
  <div>
    <div class="two-tiles">
      <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(submit)">
          <loader :loader="loader" />
          <md-card :class="[
            'md-layout-item',
            'md-small-size-100',
            { 'md-size-100': verificationObject },
            { 'md-size-66': !verificationObject, center: !verificationObject },
          ]">
            <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
              <div class="card-icon">
                <md-icon>perm_identity</md-icon>
              </div>
              <h4 class="title">
                {{ $t('contactData') }}
              </h4>
              <h3>{{ $t('editContactText') }}</h3>
            </md-card-header>
            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>{{ $t('contactId') }}</label>
                    <md-input v-model="contact.ID" type="text" disabled></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>{{ $t('customerNumber') }}</label>
                    <md-input v-model="contact.CustomerNr" type="text" disabled></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50 salutation">
                  <md-field>
                    <label for="salutation">{{ $t('salutation') }}</label>
                    <md-select v-model="contact.Salutation" name="salutation" id="salutation">
                      <md-option value="Herr">{{ $t('mr') }}</md-option>
                      <md-option value="Frau">{{ $t('ms') }}</md-option>
                      <md-option value="NoSalutation">{{ $t('noSalutaition') }}</md-option>
                    </md-select>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>{{ $t('title') }}</label>
                    <md-input v-model="contact.Title" type="text"></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <div>
                    <ValidationProvider name="firstname" rules="required" v-slot="{ passed, failed }">
                      <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                        <label>{{ $t('firstName') }}</label>
                        <md-input v-model="contact.FirstName" type="text"></md-input>
                        <slide-y-down-transition>
                          <md-icon class="error" v-show="failed">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon class="success" v-show="passed">done</md-icon>
                        </slide-y-down-transition>
                      </md-field>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <div>
                    <ValidationProvider name="lastname" rules="required" v-slot="{ passed, failed }">
                      <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                        <label>{{ $t('lastName') }}</label>
                        <md-input v-model="contact.LastName" type="text"></md-input>
                        <slide-y-down-transition>
                          <md-icon class="error" v-show="failed">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon class="success" v-show="passed">done</md-icon>
                        </slide-y-down-transition>
                      </md-field>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label for="gender">{{ $t('gender') }}</label>
                    <md-select v-model="contact.Gender" name="gender" id="gender">
                      <md-option value="männlich">{{ $t('male') }}</md-option>
                      <md-option value="weiblich">{{ $t('female') }}</md-option>
                      <md-option value="divers">{{ $t('diverse') }}</md-option>
                    </md-select>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>{{ $t('phoneNumber') }}</label>
                    <md-input v-model="contact.Phone" type="text"></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                    <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                      <label>{{ $t('email') }}</label>
                      <md-input v-model="contact.Email" type="email"> </md-input>
                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>{{ $t('position') }}</label>
                    <md-input v-model="contact.Position" type="text"></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <md-checkbox v-model="contact.MainContact" class="imo-checkbox">{{ $t('mainContact') }}
                    </md-checkbox>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <select-country :value="contact.Language.toUpperCase()"
                    @changeCountry="handleCountry"></select-country>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>{{ $t('createdBy') }}</label>
                    <md-input v-model="contact.CreatedBy" type="text" disabled></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>{{ $t('createdOn') }}</label>
                    <md-input v-model="contact.Created" type="text" disabled></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>{{ $t('modifiedBy') }}</label>
                    <md-input v-model="contact.ModifiedBy" type="text" disabled></md-input>
                  </md-field>
                </div>

                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>{{ $t('modifiedOn') }}</label>
                    <md-input v-model="contact.Modified" type="text" disabled></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50 text-right">
                  <!-- <md-button class="md-raised md-primary mt-4" @click="manageProducts()"
                    >{{ $t('manageProducts') }}
                  </md-button> -->
                </div>
                <div class="md-layout-item md-size-50 text-right remove-flex md-small-size-100">
                  <md-button v-if="allow('{66f669e2-c677-436c-9afa-0bb0c7f95633}')" id="submit"
                    class="md-raised md-primary mt-4" @click="updateContact()" :disabled="invalid">{{
                      $t('updateContactText') }}
                  </md-button>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </ValidationObserver>
      <md-card class="student-data md-layout-item md-medium-size-33 md-size-33 md-small-size-100"
        v-if="verificationObject || imaStudent2022Exists">
        <loader :loader="loaderStudent" />
        <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
          <div class="card-icon">
            <md-icon>folder_open</md-icon>
          </div>
          <h4 class="title">
            {{ $t('studentData') }}
          </h4>
          <h3>{{ $t('reviewStudentData') }}</h3>
        </md-card-header>
        <md-card-content>
          <div class="md-layout student-data-layout">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label>{{ $t('studentEmail') }}</label>
                <md-input v-model="studentEmail" type="text" disabled></md-input>
              </md-field>
              <md-field>
                <label class="status-label">Status</label>
                <md-input v-model="StatusObj[StatusNumber]" disabled :key="renderKey"></md-input>
              </md-field>
              <md-field class="uni-selection">
                <select-vue v-model="selectedStudentDomain" :options="this.filteredStudentDomains"
                  :placeholder="this.$t('selectUni')" :key="renderKey" class="uni-selection-dropdown"></select-vue>
              </md-field>

              <div class="resend-mail" v-if="StatusNumber === 2">
                <span class="md-primary">
                  {{ $t('resendUniConfirm') }}
                  <a @click="resendMail">{{ $t('resendMailLink') }}.</a>
                </span>
              </div>

              <div class="md-layout uni-domain-button">
                <div>
                  <md-button class="md-raised md-primary" @click="changeVerificationStatus('3')"
                    v-if="StatusNumber === 3">{{ $t('updateUni') }}
                  </md-button>
                </div>
                <div v-if="allow('{7570126c-4a71-4971-aa7f-950977f49729}') && StatusNumber === 3">
                  <md-button @click="$router.push({ name: 'Add Student Domain' })" class="md-primary">{{ $t('addUni') }}
                  </md-button>
                </div>
              </div>

              <div class="md-layout-item buttons-status4" v-if="StatusNumber === 4 || StatusNumber === 6">
                <md-button class="md-raised md-primary left-button" @click="changeVerificationStatus('3')"
                  :disabled="!(selectedStudentDomain > 1)">{{ $t('verifyStudentEmail') }}
                </md-button>

                <div class="md-layout-item" v-if="allow('{7570126c-4a71-4971-aa7f-950977f49729}')">
                  <md-button @click="$router.push({ name: 'Add Student Domain' })" class="md-primary">{{ $t('addUni') }}
                  </md-button>
                </div>

                <md-button class="md-raised md-primary right-button" @click="changeVerificationStatus('5')">{{
                  $t('requestIMA') }}
                </md-button>
              </div>
              <div v-if="!(selectedStudentDomain > 1) && StatusNumber === 4">{{ $t('selectUniReminder') }}</div>

              <h3 v-if="imaFilesData.length">{{ $t('imaFiles') }}</h3>
              <div v-for="(item, index) in imaFilesData" :key="index" class="download-ima-section">
                <div class="download-ima-name" @click="downloadIma(imaFilesData[index].ImaFile, item.Name)">
                  {{ item.Name }}
                </div>
                <div class="ima-actions">
                  <div class="download-delete-ima" @click="downloadIma(imaFilesData[index].ImaFile, item.Name)">
                    <md-icon @click="downloadIma(imaFilesData[index].ImaFile, item.Name)"
                      class="download-icon">file_download</md-icon>
                  </div>
                </div>
              </div>
              <div v-if="StatusNumber === 3">
                <div>
                  <h3>{{ productData[studentProductID] }}</h3>
                  <div class="downloads-and-date">
                    <div class="md-layout-item md-small-size-100 md-size-40">
                      <md-field>
                        <label>{{ $t('numberOfDownloads') }}</label>
                        <md-input v-model="numberOfDownloadsStudentProduct" type="text" disabled></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-50">
                      <md-field>
                        <label>{{ $t('lastDownloadDate') }}</label>
                        <md-input v-model="lastDownloadDateStudent2024" type="text" disabled></md-input>
                      </md-field>
                    </div>
                  </div>
                  <div :class="{ ['buttons-alignment']: studentPaymentExist }">
                    <div class="button-align-left" v-if="studentPaymentExist">
                      <md-button class="md-raised md-primary" @click="changeTestOrderStatus(!testOrder)">{{
                        $t('setTestorder') + ': ' + !testOrder }}
                      </md-button>
                    </div>
                    <div class="flex-inline-licence">
                      <div class="button-align-left">
                        <md-button class="md-raised md-primary" @click="generateNewLicence('Studienversion 2024')">{{
                          $t('generateLicence') }}
                        </md-button>
                      </div>
                      <div class="button-align-right">
                        <md-button class="md-raised md-primary" @click="getLicenceFile('Studienversion 2024')">{{
                          $t('donwloadLicence') }}
                        </md-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="additionalProductID">
                  <h3>{{ productData[additionalProductID] }}</h3>
                  <md-field>
                    <label>{{ $t('numberOfDownloads') }}</label>
                    <md-input v-model="numberOfDownloadsRMProduct" type="text" disabled></md-input>
                  </md-field>
                  <div class="button-align-right">
                    <md-button class="md-raised md-primary" @click="getLicenceFile('RM 2023')">{{ $t('donwloadLicence')
                      }}
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="md-layout-item buttons-status4"
              v-if="StatusNumber === 6 || (StatusNumber === 2 && this.imaFilesData.length)">
              <md-button class="md-raised md-primary left-button" @click="changeVerificationStatus('3')"
                :disabled="!(selectedStudentDomain > 1)">{{ $t('verifyIMA') }}
              </md-button>
              <md-button class="md-raised md-primary right-button"
                @click="changeVerificationStatus('7') || (StatusNumber === 2 && this.imaFilesData.length)">{{
                  $t('requestNewIMA') }}
              </md-button>
            </div>
            <div v-if="!(selectedStudentDomain > 1) && StatusNumber === 6">{{ $t('selectUniReminder') }}</div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <div class="payment-card manual-payment md-layout-item md-medium-size-66 md-size-66 md-small-size-100"
      :class="{ 'center-payments-card': !(verificationObject || imaStudent2022Exists) }">
      <!-- v-if="StatusNumber === 3" -->
      <md-card :class="{ 'no-verificationObj': !verificationObject }" v-if="true">
        <loader :loader="loaderPayment" />
        <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
          <div class="card-icon">
            <md-icon>payment</md-icon>
          </div>
          <h4 class="title">
            {{ $t('payments') }}
          </h4>
        </md-card-header>
        <md-card-content>
          <h3 class="title">{{ $t('payedProducts') }}</h3>
          <div class="md-layout">
            <div v-for="(item, index) in payedProducts" :key="index"
              class="show-success-payments md-layout-item md-size-100">
              <div :key="renderKey" class="md-layout-item md-size-50 md-small-size-100">
                {{ productData[item.ProductID] }}
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                {{
                  item.Price.toLocaleString('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                Euro
              </div>
            </div>
            <div class="create-payments md-layout-item md-size-100">
              <div class="md-layout-item md-medium-size-50 md-size-50 md-small-size-100" @click="addCustomClass()">
                <md-field>
                  <label for="paymentProductID">{{ $t('productId') }}</label>
                  <md-select v-model="paymentProductID" name="paymentProductID" id="paymentProductID" :key="renderKey">
                    <md-option value="{cc20e362-7c96-4c88-b14d-4ff97bd2864b}">{{
                      this.$t('studentProduct')
                    }}</md-option>
                    <md-option value="{1138e27f-99d6-45b4-9ed7-a059bee0fb41}">{{
                      this.$t('mDesign2022SecondEdition')
                    }}</md-option>
                    <md-option value="{19437920-8791-4987-b069-8e99c9b080b0}">{{
                      this.$t('vdiSoftwareBoltCalculation')
                    }}</md-option>
                    <md-option value="{691a838c-0e9f-11ee-b3bc-0050561ebe44}">{{
                      this.$t('mDesignRoloffMatekEdition2023')
                    }}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-medium-size-50 md-size-50 md-small-size-100">
                <md-field>
                  <label>{{ $t('price') }}</label>
                  <md-input v-model="productPrice" type="number" step="0.1" min="0"></md-input>
                </md-field>
              </div>
            </div>
            <div class="button-align-right md-layout-item md-size-100">
              <md-button class="md-primary create-payment-button" @click="createPayment()">{{ $t('createPayment') }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <div class="contact-products md-layout-item" :class="{ 'mr-15 md-size-66 center': !verificationObject }">
      <products-list :contactProducts="true"></products-list>
    </div>
    <!--     
    
      <md-card class="md-layout-item contact-products-card" :class="{ 'md-size-66 center': !verificationObject }">
        <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
          <div class="card-icon">
            <md-icon>inventory</md-icon>
          </div>
          <h4 class="title">
            {{ $t('myproducts') }}
          </h4>
        </md-card-header>
        
      </md-card>
    </div> -->
  </div>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate'
import { required, min_value, email, numeric } from 'vee-validate/dist/rules'
import SelectCountry from '../../components/SelectCountry/SelectCountry.vue'
import { useCompanyStore } from '../../stores/company'
import { useUserStore } from '../../stores/user'
import Swal from 'sweetalert2'
import {
  sendEmailAboutStatusChangeToStudent,
  sendEmailConfirmStudentEmail,
  sendEmailStudent2024PaymentRecieved,
} from '@/libs/emailSender.js'
import ProductsList from '../Products/ProductsComponent/ProductsList.vue'
import { permissionMixin } from '../RoleManagement/permissionMixin'
import { httpPost, ApiNames } from '@/libs/httpHelper'

extend('required', required)
extend('min_value', min_value)
extend('email', email)
extend('numeric', numeric)

export default {
  mixins: [permissionMixin],
  components: { SelectCountry, ValidationProvider, ProductsList },
  name: 'edit-contact-form',
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    return { companyStore, userStore }
  },
  data() {
    return {
      contact: {
        ID: '',
        CompanyID: '',
        Salutation: '',
        Title: '',
        FirstName: '',
        LastName: '',
        Gender: '',
        Phone: '',
        Email: '',
        Position: '',
        MainContact: false,
        Language: '',
        Image: 0,
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
      },
      provideProofStatus: this.$t('provideProofStatus'),
      studentEmailNotConfirmed: this.$t('studentEmailNotConfirmed'),
      verifiedStatus: this.$t('verifiedStatus'),
      emailVerificationNeededStatus: this.$t('emailVerificationNeededStatus'),
      imaRequestedStatus: this.$t('imaRequestedStatus'),
      imaVerificationNeededStatus: this.$t('imaVerificationNeededStatus'),
      imaNotReadeableStatus: this.$t('imaNotReadeableStatus'),
      StatusObj: {
        1: this.provideProofStatus,
        2: this.studentEmailNotConfirmed,
        3: this.verifiedStatus,
        4: this.emailVerificationNeededStatus,
        5: this.imaRequestedStatus,
        6: this.imaVerificationNeededStatus,
        7: this.imaNotReadeableStatus,
        //  8: this.licenceExpiredStatus,
      },
      StatusNumber: this.StatusNumber,
      studentProductID: '{cc20e362-7c96-4c88-b14d-4ff97bd2864b}',
      roloffMatekProductID: '{691a838c-0e9f-11ee-b3bc-0050561ebe44}',
      deckerProductID: '{d0b9464d-7b53-4b15-b97b-09bcc5898a72}',
      student2022ProductID: '{14dc88db-5d79-447a-9bd6-193ae1fc0c90}',
      verificationObject: '',
      loader: false,
      renderKey: 0,
      dataFileIDs: [],
      imaFileNames: [],
      imaFilesData: [],
      filteredStudentDomains: [],
      search: '',
      licenceDataObj: [],
      numberOfDownloadsStudentProduct: 0,
      numberOfDownloadsRMProduct: 0,
      loaderStudent: false,
      loaderPayment: false,
      hovering: false,
      payedProducts: [],
      mDesign2022SecondEdition: this.$t('mDesign2022SecondEdition'),
      studentProduct: this.$t('studentProduct'),
      vdiSoftwareBoltCalculation: this.$t('vdiSoftwareBoltCalculation'),
      mDesignRoloffMatekEdition2023: this.$t('mDesignRoloffMatekEdition2023'),
      // student2022Product: this.$t('studyVersion2022'),
      productData: {
        '{cc20e362-7c96-4c88-b14d-4ff97bd2864b}': this.studentProduct,
        '{1138e27f-99d6-45b4-9ed7-a059bee0fb41}': this.mDesign2022SecondEdition,
        '{19437920-8791-4987-b069-8e99c9b080b0}': this.vdiSoftwareBoltCalculation,
        '{691a838c-0e9f-11ee-b3bc-0050561ebe44}': this.mDesignRoloffMatekEdition2023,
        // '{14dc88db-5d79-447a-9bd6-193ae1fc0c90}': this.student2022Product,
      },
      paymentProductID: '',
      productPrice: '',
      additionalProductID: '',
      responseAdditionalVerification: null,
      responseStudentVerification: null,
      studentEmail: '',
      selectedStudentDomain: '',
      imaStudent2022Exists: false,
      lastDownloadDateStudent2024: '',
      testOrder: '',
      studentPaymentExist: false,
    }
  },
  async mounted() {
    this.loader = true
    this.assignStatusText()
    // contact in params übergeben
    if (!this.userStore.editContactID) this.$router.push({ name: 'Contacts List' })
    this.contact = this.userStore.editContactID
    await this.getStudentVerificationData()
    await this.getFileNames()
    await this.getImaFile()
    await this.getLicenceDownloadCount()
    await this.getPaidProducts()
    this.assignStatusText()
    this.assignProductName()
    await this.getStudent2022ImaFiles()
    this.renderKey++
    this.$changePageTitle(this.$t('pageTitleEditContactProfile'))
    this.loader = false
  },
  methods: {
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    assignStatusText() {
      this.studentEmailNotConfirmed = this.$t('studentEmailNotConfirmed')
      this.verifiedStatus = this.$t('verifiedStatus')
      this.emailVerificationNeededStatus = this.$t('emailVerificationNeededStatus')
      this.imaRequestedStatus = this.$t('imaRequestedStatus')
      this.imaVerificationNeededStatus = this.$t('imaVerificationNeededStatus')
      this.imaNotReadeableStatus = this.$t('imaNotReadeableStatus')
      this.provideProofStatus = this.$t('provideProofStatus')
      this.StatusObj = {
        1: this.provideProofStatus,
        2: this.studentEmailNotConfirmed,
        3: this.verifiedStatus,
        4: this.emailVerificationNeededStatus,
        5: this.imaRequestedStatus,
        6: this.imaVerificationNeededStatus,
        7: this.imaNotReadeableStatus,
      }
      this.renderKey++
    },
    filterStudentDomains() {
      this.filteredStudentDomains = this.studentDomains.map((item) => {
        return {
          id: item.ID,
          text: item.DescriptionEN + ' (' + item.DomainName + ')',
        }
      })
    },
    handleCountry(val) {
      this.contact.Language = val.value.toLowerCase()
    },
    manageProducts() {
      this.$router.push({
        name: 'Contact Products',
      })
    },
    async updateContact() {
      this.contact.ModifiedBy = this.userStore.userData.LastName
      this.contact.Modified = this.companyStore.formatDate(new Date())
      this.loader = true
      const response = await this.companyStore.updateCompanyContactData(this.contact, this.userStore.token)
      this.loader = false
      if (response.status === 200) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('profileUpdated'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
          background: '#ffffff',
          confirmButtonClass: 'md-button md-success btn-fill',
        })
      } else {
        Swal.fire({
          title: this.$t('error'),
          html: response.statusText,
          icon: 'error',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
      }
    },
    async getAdditionalProduct() {
      // || item.ProductID === this.student2022ProductID
      const response = await this.userStore.getUserProducts(this.contact.ID)
      const additionalProduct = response?.length
        ? response.find(
          (item) => item.ProductID === this.roloffMatekProductID || item.ProductID === this.deckerProductID,
        )
        : null
      this.additionalProductID = additionalProduct ? additionalProduct.ProductID : ''
    },
    async getStudentVerificationData() {
      this.responseStudentVerification = await this.userStore.getUserVerification(
        this.studentProductID,
        this.contact.ID,
      )
      await this.getAdditionalProduct()
      //we check roloff matek product verification or decker product verification
      if (this.additionalProductID) {
        this.responseAdditionalVerification = await this.userStore.getUserVerification(
          this.additionalProductID,
          this.contact.ID,
        )
        this.verificationObject = this.responseStudentVerification.length
          ? this.responseStudentVerification[0]
          : this.responseAdditionalVerification
            ? this.responseAdditionalVerification[0]
            : null
      } else {
        this.verificationObject = this.responseStudentVerification?.length ? this.responseStudentVerification[0] : null
      }
      if (this.verificationObject) {
        this.studentDomains = this.userStore.studentDomains
        this.filterStudentDomains()

        this.selectedStudentDomain = this.responseStudentVerification.length
          ? this.responseStudentVerification[0].StudentDomainID != -1
            ? this.responseStudentVerification[0].StudentDomainID
            : this.responseAdditionalVerification?.length
              ? this.responseAdditionalVerification[0].StudentDomainID
              : -1
          : this.responseAdditionalVerification.length
            ? this.responseAdditionalVerification[0].StudentDomainID
            : -1
      }

      this.StatusNumber = this.verificationObject?.Status
      this.studentEmail = this.responseStudentVerification?.length
        ? this.responseStudentVerification[0].StudentEmail
          ? this.responseStudentVerification[0].StudentEmail
          : this.responseAdditionalVerification?.length
            ? this.responseAdditionalVerification[0].StudentEmail
            : ''
        : this.responseAdditionalVerification?.length
          ? this.responseAdditionalVerification[0].StudentEmail
          : ''
    },
    async changeVerificationStatus(newStatus) {
      this.loaderStudent = true
      let verifiedStatus = false
      this.selectedStudentDomain = +this.selectedStudentDomain
      if (newStatus === '3' && this.selectedStudentDomain > 0) {
        verifiedStatus = true
      }
      if (newStatus === '3' && this.selectedStudentDomain === -1) {
        await this.createVerificationIfNotExists()
        const response = await this.userStore.changeStatusOfVerification(
          this.studentProductID,
          this.contact.ID,
          newStatus,
          this.verificationObject.ID,
          verifiedStatus,
          this.selectedStudentDomain,
        )
        if (response.status === 404) {
          Swal.fire({
            title: this.$t('error'),
            html: this.$t('noUniSelected'),
            icon: 'error',
            confirmButtonText: this.$t('ok'),
            confirmButtonClass: 'md-button md-success btn-fill',
          })
          this.loaderStudent = false
          return
        }
        newStatus = '1'
        verifiedStatus = false
      }
      //we need to check that for both products are created verifications
      await this.createVerificationIfNotExists()
      const studentVerificationID = this.responseStudentVerification[0]?.ID || this.responseStudentVerification.data
      const response = await this.userStore.changeStatusOfVerification(
        this.studentProductID,
        this.contact.ID,
        newStatus,
        studentVerificationID,
        verifiedStatus,
        this.selectedStudentDomain,
      )
      if (this.additionalProductID) {
        const additionalVerificationID =
          this.responseAdditionalVerification[0]?.ID || this.responseAdditionalVerification.data
        await this.userStore.changeStatusOfVerification(
          this.additionalProductID,
          this.contact.ID,
          newStatus,
          additionalVerificationID,
          verifiedStatus,
          this.selectedStudentDomain,
        )
      }
      this.loaderStudent = false
      if (response.status === 200) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('statusChanged') + ' ' + this.StatusObj[newStatus],
          icon: 'success',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
          background: '#ffffff',
        })
        this.renderKey++
        await this.getStudentVerificationData()
        this.renderKey++
        const companyID = this.contact.CompanyID === 'NULL' ? '' : this.contact.CompanyID
        const additionalVerificationStudentData = this.responseAdditionalVerification?.length
          ? this.responseAdditionalVerification[0].StudentEmail
            ? this.responseAdditionalVerification[0].StudentEmail
            : this.responseAdditionalVerification[0].ImaData > 0
          : null
        if (additionalVerificationStudentData && newStatus == 3) {
          await sendEmailAboutStatusChangeToStudent(
            companyID,
            this.studentProductID,
            this.contact.ID,
            this.contact.Email,
            this.userStore.token,
            8,
            this.contact.FirstName,
            this.contact.LastName,
          )
        } else {
          await sendEmailAboutStatusChangeToStudent(
            companyID,
            this.studentProductID,
            this.contact.ID,
            this.contact.Email,
            this.userStore.token,
            newStatus,
            this.contact.FirstName,
            this.contact.LastName,
          )
        }
      } else {
        Swal.fire({
          title: this.$t('error'),
          html: response.statusText,
          icon: 'error',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
      }
    },
    async resendMail() {
      Swal.fire({
        icon: 'question',
        html: this.$t('askResendMail'),
        showConfirmButton: true,
        showCancelButton: true,
      }).then(async (result) => {
        if (!result.isConfirmed) return
        const customConfig = {
          headers: {
            'Content-Type': 'application/json',
          },
        }

        var objEmailReciever = {
          Email: this.userStore.editContactID.Email,
        }
        var base64Reciever = btoa(JSON.stringify(objEmailReciever))
        var responseToken = await httpPost(ApiNames.createtoken, base64Reciever, customConfig)
        if (responseToken.status === 200) {
          const token = responseToken.data

          const responseVerifyEmail = await sendEmailConfirmStudentEmail(
            token,
            this.studentEmail,
            this.userStore.editContactID.FirstName,
            this.userStore.editContactID.LastName,
          )

          if (responseVerifyEmail === 'Email is sended.') {
            Swal.fire({
              icon: 'success',
              html: this.$t('emailSentOnly'),
              showConfirmButton: true,
            })
          } else {
            Swal.fire({
              icon: 'error',
              html: this.$t('errorSendMail'),
              showConfirmButton: true,
            })
          }
        } else {
          Swal.fire({
            icon: 'error',
            html: this.$t('errorAuthorization'),
            showConfirmButton: true,
          })
        }
      })
    },
    async getFileNames() {
      if (!this.responseStudentVerification && !this.responseAdditionalVerification) {
        return
      }
      let responseGetFileRefByTable = []
      if (this.responseStudentVerification.length) {
        const responseGetFileRefByTableStudent = await this.userStore.getFileReferenceByTable(
          this.responseStudentVerification[0].ID,
          'verification',
        )
        responseGetFileRefByTable = [
          ...(responseGetFileRefByTable ?? []),
          ...(responseGetFileRefByTableStudent?.data ?? []),
        ]
      }
      if (this.responseAdditionalVerification?.length) {
        const responseGetFileRefByTableAdditional = await this.userStore.getFileReferenceByTable(
          this.responseAdditionalVerification[0].ID,
          'verification',
        )
        responseGetFileRefByTable = [
          ...(responseGetFileRefByTable ?? []),
          ...(responseGetFileRefByTableAdditional?.data ?? []),
        ]
      }

      if (!responseGetFileRefByTable) {
        return
      }
      responseGetFileRefByTable.forEach((element) => {
        this.dataFileIDs.push(element.DataFileID)
      })
      for (const element of this.dataFileIDs) {
        const response = await this.userStore.getDataFileDetails(element)
        //here we check if the response array has a licence
        if (response.data[0].Filetype === 'text/lic') {
          const licenceFile = {
            DataFileID: response.data[0].ID,
            Title: response.data[0].Title,
          }
          this.licenceDataObj.push(licenceFile)
          continue
        }
        this.imaFileNames.push(response.data[0].Name)
        this.imaFilesData.push({
          DataFileID: element,
          Name: response.data[0].Name,
        })
      }
    },
    async downloadIma(File, Name) {
      let file
      const reader = new FileReader()
      reader.onload = () => {
        file = reader.result
        const linkSource = file
        const downloadLink = document.createElement('a')
        downloadLink.href = linkSource
        downloadLink.download = Name
        downloadLink.click()
      }
      reader.readAsDataURL(File)
    },
    async getImaFile() {
      if (!this.verificationObject) {
        return
      }
      if (!this.dataFileIDs) {
        return
      }
      for (let index = 0; index < this.imaFilesData.length; index++) {
        const responseDownloadImaFile = await this.userStore.getDownloadFile(this.imaFilesData[index].DataFileID)
        this.imaFilesData[index] = {
          ...this.imaFilesData[index],
          ImaFile: responseDownloadImaFile.data,
        }
      }
    },
    async getLicenceFile(title) {
      this.loaderStudent = true
      let licenceFile
      let result
      for (const licence of this.licenceDataObj) {
        if (licence.Title === title) {
          result = await this.userStore.getDownloadFile(licence.DataFileID)
          licenceFile = result.data
        }
      }
      if (!result) {
        result = await this.generateLicence(title)
      }
      this.downloadLicence(result)

      this.loaderStudent = false
    },
    async generateLicence(title) {
      const verification =
        title === 'Studienversion 2024'
          ? this.responseStudentVerification[0].ID
          : this.responseAdditionalVerification[0].ID
      const result = await this.userStore.generateStudentLicense(
        this.contact.FirstName,
        this.contact.LastName,
        this.contact.Email,
        this.contact.ID,
        verification,
        title,
      )
      return result
    },
    downloadLicence(fileData) {
      if (fileData.status === 200) {
        this.loader = false
        const blobData = fileData.data
        // Create a blob URL
        const blobUrl = URL.createObjectURL(blobData)
        // Create an anchor element
        const link = document.createElement('a')
        // Set the href attribute to the blob URL
        link.href = blobUrl
        // Specify the download attribute and the desired file name
        link.download = 'mdesign_general_2024.lic' // Replace 'yourFileName.ext' with the desired file name
        // Append the link to the body (you can also append it to another element)
        document.body.appendChild(link)
        // Trigger a click on the link to start the download
        link.click()
        // Remove the link from the DOM
        document.body.removeChild(link)
        // Revoke the blob URL to free up resources
        URL.revokeObjectURL(blobUrl)
      } else {
        this.loaderStudent = false
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: this.$t('setNewPassErrorPopup'),
        })
      }
    },
    async generateNewLicence(title) {
      this.loaderStudent = true
      this.licenceDataObj.map(async (item) => {
        if (item.Title === "Studienversion 2024") {
          await this.userStore.deleteDataFile(item.DataFileID)
        }
      })
      const res = await this.generateLicence(title)
      this.downloadLicence(res)
      this.dataFileIDs = []
      this.licenceDataObj = []
      this.imaFileNames = []
      this.imaFilesData= []
      await this.getStudentVerificationData()
      await this.getFileNames()
      await this.getLicenceDownloadCount()
      await this.getStudent2022ImaFiles()
      this.loaderStudent = false
    },
    async getLicenceDownloadCount() {
      let response = await this.userStore.getDownload(this.contact.ID)
      if (response) {
        response.forEach((element) => {
          this.licenceDataObj.forEach((licence) => {
            if (element.DataFileID === licence.DataFileID && licence.Title === 'Studienversion 2024') {
              this.numberOfDownloadsStudentProduct = element.DownloadCount
              this.lastDownloadDateStudent2024 = this.changeDateFormat(element.LastDownloadDate)
            }
            if (element.DataFileID === licence.DataFileID && licence.Title === 'RM 2023') {
              this.numberOfDownloadsRMProduct = element.DownloadCount
            }
          })
        })
      }
    },
    changeDateFormat(isoString) {
      if (!isoString) {
        return ''
      }

      // Parse the ISO string into a Date object
      const date = new Date(isoString)

      // Extract the time components
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')

      // Extract the date components
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
      const year = date.getFullYear()

      // Format the time and date into the desired format
      const formattedTime = `${hours}:${minutes}`
      const formattedDate = `${day}.${month}.${year}`
      const formattedString = `${formattedDate} ${formattedTime} `
      return formattedString
    },
    updateValidityStartDate() {
      const startDate = new Date().toISOString()
      this.userStore.updateValidityStartDate(
        this.verificationObject.ID,
        this.contact.ID,
        this.studentProductID,
        this.selectedStudentDomain,
        startDate,
      )
    },
    async getPaidProducts() {
      this.studentProduct = this.$t('studentProduct')
      const response = await this.userStore.getOrderingByContact(this.contact.ID)
      response.status === 200 ? ((this.payedProducts = response.data), this.getTestOrderStatus(response.data)) : null
      this.renderKey++
    },
    getTestOrderStatus(payedProducts) {
      payedProducts.forEach((element) => {
        if (element.ProductID === '{cc20e362-7c96-4c88-b14d-4ff97bd2864b}') {
          this.studentPaymentExist = true
          element.Testorder ? (this.testOrder = true) : (this.testOrder = false)
        }
      })
    },
    async changeTestOrderStatus(testOrderValue) {
      const productID = '{cc20e362-7c96-4c88-b14d-4ff97bd2864b}'
      Swal.fire({
        title: this.$t('changeTestorderValue'),
        html: this.$t('setTestorder') + ': ' + testOrderValue,
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
      }).then(async (result) => {
        if (!result.isConfirmed) return
        const orderID = this.payedProducts.find((element) => element.ProductID === productID).ID
        const contactID = this.contact.ID
        this.loader = true
        const response = await this.userStore.changeTestOrderValue(orderID, contactID, productID, testOrderValue)
        this.loader = false
        if (response.status === 200) {
          this.loader = true
          await this.getPaidProducts()
          this.loader = false
          Swal.fire({
            title: this.$t('success'),
            html: this.$t('statusChanged') + ' ' + this.testOrder,
            icon: 'success',
            confirmButtonText: this.$t('ok'),
            confirmButtonClass: 'md-button md-success btn-fill',
            background: '#ffffff',
          })
          this.renderKey++
        } else {
          Swal.fire({
            title: this.$t('error'),
            icon: 'error',
            confirmButtonText: this.$t('ok'),
            confirmButtonClass: 'md-button md-error btn-fill',
          })
        }
      })
    },
    assignProductName() {
      this.mDesign2022SecondEdition = this.$t('mDesign2022SecondEdition')
      this.studentProduct = this.$t('studentProduct')
      this.vdiSoftwareBoltCalculation = this.$t('vdiSoftwareBoltCalculation')
        ; (this.mDesignRoloffMatekEdition2023 = this.$t('mDesignRoloffMatekEdition2023')),
          (this.productData = {
            '{cc20e362-7c96-4c88-b14d-4ff97bd2864b}': this.studentProduct,
            '{1138e27f-99d6-45b4-9ed7-a059bee0fb41}': this.mDesign2022SecondEdition,
            '{19437920-8791-4987-b069-8e99c9b080b0}': this.vdiSoftwareBoltCalculation,
            '{691a838c-0e9f-11ee-b3bc-0050561ebe44}': this.mDesignRoloffMatekEdition2023,
          })
      this.renderKey++
    },
    async createPayment() {
      this.loaderPayment = true
      const response = await this.userStore.createOrdering(
        this.contact.CompanyID,
        this.contact.ID,
        this.paymentProductID,
        this.productPrice,
      )
      await this.getPaidProducts()

      this.loaderPayment = false
      if (response.status === 200) {
        await sendEmailStudent2024PaymentRecieved(this.contact.FirstName, this.contact.LastName, this.contact.Email)
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('succesfulPayment'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
      } else {
        Swal.fire({
          title: this.$t('error'),
          html: this.$t('profileNotUpdated'),
          icon: 'error',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
      }
    },
    addCustomClass() {
      // Use setTimeout to ensure that the class is added after the menu is rendered
      const selectMenu = document.querySelector('.md-select-menu')
      if (selectMenu) {
        selectMenu.classList.add('product-select-menu')
      }
    },
    async createVerificationIfNotExists() {
      if (this.additionalProductID && !this.responseAdditionalVerification.length) {
        //TODO: create verification for additional product
        this.responseAdditionalVerification = await this.userStore.createVerification(
          this.additionalProductID,
          this.contact.ID,
        )
      }
      if (!this.responseStudentVerification.length) {
        //create verification for student product
        this.responseStudentVerification = await this.userStore.createVerification(
          this.studentProductID,
          this.contact.ID,
        )
      }
    },
    async getStudent2022ImaFiles() {
      const responseStudent2022Verification = await this.userStore.getUserVerification(
        this.student2022ProductID,
        this.contact.ID,
      )
      if (responseStudent2022Verification?.length && responseStudent2022Verification[0].ImaData > 0) {
        this.imaStudent2022Exists = true
        const student2022VerificationID = responseStudent2022Verification[0].ID
        const responseGetFileRefByTableStudent = await this.userStore.getFileReferenceByTable(
          student2022VerificationID,
          'verification',
        )
        const dataFileRefStudent2022 = responseGetFileRefByTableStudent.data
        let student2022imaFileIDs = []
        dataFileRefStudent2022.forEach((element) => {
          student2022imaFileIDs.push(element.DataFileID)
        })
        const imaFilesDataStudent2022 = []
        for (const element of student2022imaFileIDs) {
          const response = await this.userStore.getDataFileDetails(element)
          //here we check if the response array has a licence
          const responseDownloadImaFile = await this.userStore.getDownloadFile(element)
          if (response.data[0].Filetype != 'text/lic') {
            this.imaFilesData.push({
              DataFileID: element,
              Name: response.data[0].Name,
              ImaFile: responseDownloadImaFile.data,
            })
          }
        }
      }
    },
  },
  watch: {
    '$i18n.locale': {
      handler(newLanguage) {
        this.assignStatusText()
        this.renderKey++
      },
    },
  },
}
</script>

<style scoped>
form {
  margin: 0 auto;
  margin-right: 15px;

  @media (max-width: 960px) {
    max-width: 96% !important;
    margin: 0 auto;
  }
}

.center {
  margin: 0 auto;
}

.student-data {
  margin-top: 30px;

  @media (max-width: 960px) {
    max-width: 96% !important;
    margin: 0 auto !important;
  }
}

@media (max-width: 960px) {
  .student-data.md-layout-item.md-small-size-100 {
    min-width: 96%;
    max-width: 96%;
    flex: 1 1 96%;
  }

  .payment-card {
    padding: 0;

    .md-card {
      max-width: 96% !important;
      margin: 30px auto 0 auto;
    }
  }

  .show-success-payments,
  .downloads-and-date {
    flex-direction: column;
  }
}

.buttons-status4 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0;
}

.status-label {
  padding-bottom: 20px;
}

.download-ima-section,
.show-success-payments,
.create-payments {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 100%;
}

.download-ima-name,
.download-icon {
  cursor: pointer;
}

/deep/.select2-container {
  width: 100% !important;
  border-bottom: 1px dotted #d2d2d2 !important;
}

/deep/.select2-selection {
  border: none !important;
}

/deep/.select2-selection__rendered {
  padding-left: 0px !important;
  color: var(--md-theme-default-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important;
  font-size: 14px !important;
}

.uni-selection {
  margin: 13px 0 0;
  display: flex;
  flex-direction: column;
}

.md-button {
  text-wrap: wrap;
}

.left-button {
  margin-right: 15px;
}

.right-button {
  margin-left: 15px;
}

.student-data-layout {
  display: flex;
  flex-direction: column;
}

.two-tiles {
  display: flex;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.create-payment-button {
  height: 27px !important;
  align-self: flex-end;
  margin-right: 15px;
}

@media (max-width: 960px) {
  .create-payments {
    flex-direction: column;
  }

  .payment-card {
    max-width: 96% !important;
    margin: 0 auto;
  }
}

.payment-card.md-layout-item {
  padding-left: 0;
}

.md-checkbox {
  margin-right: 0 !important;

  label {
    padding-left: 0 !important;
  }
}

.mr-15 {
  padding-right: 15px;
}

.contact-products {
  margin-top: 4rem;
}

.no-verificationObj {
  margin: 2rem auto;
}

.button-align-right {
  display: flex;
  justify-content: right;
}

.button-align-left {
  display: flex;
  justify-content: left;
}

.remove-flex {
  flex: none;
}

.downloads-and-date,
.buttons-alignment {
  display: flex;
  justify-content: space-between;

  .md-layout-item {
    padding: 0;
  }
}

.center-payments-card {
  margin: 0 auto;
}

.flex-inline-licence,
.uni-domain-button {
  display: flex;
  justify-content: space-between;
}
</style>
