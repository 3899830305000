<template>
  <div class="edit-product-level-content">
    <ValidationObserver v-slot="{}"
      ><!--invalid-->
      <form>
        <loader :loader="loader" />
        <!-- <md-card class="card-content">
          <md-card-content class=""> -->
        <form v-if="productLevel === 1" enctype="multipart/form-data">
          <div class="md-layout-item upload-document">
            <div class="file-input">
              <div v-if="!imageRegular">
                <div class="image-container">
                  <img :src="regularImg" title="" />
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="imageRegular" />
              </div>

              <div class="button-container">
                <md-button class="md-primary md-round md-fileinput ima-submit" v-if="imageRegular" @click="uploadImage"
                  >{{ $t('upload') }}
                </md-button>
                <md-button class="md-round md-simple" @click="removeImage" v-if="imageRegular">{{
                  $t('remove')
                }}</md-button>

                <md-button class="md-round md-primary md-fileinput ima-change" v-if="!imageRegular"
                  >{{ $t('selectImg') }}<input type="file" @change="onFileChange"
                /></md-button>
                <md-button class="md-round md-simple md-fileinput ima-change" v-else
                  >{{ $t('change') }}<input type="file" @change="onFileChange"
                /></md-button>
              </div>
            </div>
          </div>
        </form>

        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-field>
              <label>{{ $t('productName') }}</label>
              <md-input v-model="product.Name" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100">
            <md-field>
              <label>{{ $t('productLicName') }}</label>
              <md-input v-model="product.LicName" type="text"></md-input>
            </md-field>
          </div>
          <div v-if="productLevel === 1" class="md-layout-item md-size-100">
            <md-field>
              <label>{{ $t('productLicNameParent') }}</label>
              <md-input v-model="product.LicNameParent" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50" v-if="editProduct === null">
            <md-button @click="createProductLevel" class="md-round md-primary">{{ $t('create') }}</md-button>
          </div>
          <div class="md-layout-item md-size-50" v-else>
            <md-button @click="updateProductLevel" class="md-round md-primary">{{ $t('save') }}</md-button>
          </div>
          <div class="md-layout-item md-size-50">
            <md-button @click="closeModal" class="md-round md-simple">{{ $t('cancel') }}</md-button>
          </div>
        </div>
        <!-- </md-card-content>
        </md-card> -->
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '@/stores/user'
import { useConfigurationProductLevelStore } from '@/stores/configurationProductLevel'
import Swal from 'sweetalert2'
import image_placeholder from '../../../../public/img/image_placeholder.jpg'
import pdfImg from '../../../../public/img/PDF_file_icon.svg.png'
import { extend } from 'vee-validate'
import { required, min_value, email, numeric } from 'vee-validate/dist/rules'

export default {
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
    productLevel: {
      type: Number,
      required: true,
    },
    editProduct: {
      type: Object,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      renderKey: 0,
      pdf: pdfImg,
      loader: false,
      image: null,
      newAvatar: null,
      sliders: {
        simple: 5,
      },
      files: [],
      regularImg: image_placeholder,
      imageRegular: '',
      configurationHasProductLevelOne: {
        ID: '',
        ProductLevelOneID: '',
        ConfigurationID: '',
        Count: 0,
        StartDate: '',
        Limited: '',
        Test: -1,
        TestCount: 0,
        Student: -1,
        Uncounted: -1,
        EditModules: -1,
        Connect: -1,
        Sorting: -1,
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
      },
      product: {
        ID: '',
        Name: '',
        LicName: '',
        LicNameParent: '',
        Image: 0,
      },
      imageUpdate: false,
    }
  },
  async beforeMount() {
    this.loader = true
    if (!this.productLevel) this.$router.push({ name: 'Edit Product' })
    if (this.editProduct !== null) {
      if (this.productLevel === 1) {
        await this.getProductLevelOne(this.editProduct.ProductLevelOneID)
      } else if (this.productLevel === 2) {
        await this.getProductLevelTwo(this.editProduct.ProductLevelTwoID)
      } else if (this.productLevel === 3) {
        await this.getProductLevelThree(this.editProduct.ProductLevelThreeID)
      }
      if (this.product.Image === 1) {
        this.imageUpdate = true
        this.getImage()
      }
    }
    this.loader = false
  },
  beforeDestroy() {
    // this.companyStore.productData.editProduct = {}
  },
  methods: {
    async createProductLevel() {
      if (this.productLevel === 1 && this.editProduct === null) {
        var res = await this.configurationProductLevelStore.createProductLevelOne(this.product, this.userStore.token)
      }
      if (this.productLevel === 2 && this.editProduct === null) {
        var obj = {
          Name: this.product.Name,
          LicName: this.product.LicName,
        }
        var res = await this.configurationProductLevelStore.createProductLevelTwo(obj, this.userStore.token)
      }
      if (this.productLevel === 3 && this.editProduct === null) {
        var obj = {
          Name: this.product.Name,
          LicName: this.product.LicName,
        }
        var res = await this.configurationProductLevelStore.createProductLevelThree(obj, this.userStore.token)
      }
      if (res.status === 200) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('successCreateProductLevel'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
      }
      this.$emit('update')
    },
    async updateProductLevel() {
      if (this.productLevel === 1) {
        var res1 = await this.configurationProductLevelStore.updateProductLevelOne(this.product, this.userStore.token)
        if (res1.status === 200) {
          Swal.fire({
            title: this.$t('success'),
            html: this.$t('successCreateProductLevel'),
            icon: 'success',
            confirmButtonText: this.$t('ok'),
            confirmButtonClass: 'md-button md-success btn-fill',
          })
        }
        this.$emit('update')
      } else if (this.productLevel === 2) {
        var res1 = await this.configurationProductLevelStore.updateProductLevelTwo(this.product, this.userStore.token)
        if (res1.status === 200) {
          Swal.fire({
            title: this.$t('success'),
            html: this.$t('successCreateProductLevel'),
            icon: 'success',
            confirmButtonText: this.$t('ok'),
            confirmButtonClass: 'md-button md-success btn-fill',
          })
        }
        this.$emit('update')
      } else if (this.productLevel === 3) {
        var res1 = await this.configurationProductLevelStore.updateProductLevelThree(this.product, this.userStore.token)
        if (res1.status === 200) {
          Swal.fire({
            title: this.$t('success'),
            html: this.$t('successCreateProductLevel'),
            icon: 'success',
            confirmButtonText: this.$t('ok'),
            confirmButtonClass: 'md-button md-success btn-fill',
          })
        }
        this.$emit('update')
      }
    },
    async getProductLevelOne(id) {
      var res = await this.configurationProductLevelStore.getProductLevelOne(id, this.userStore.token)
      this.product = res.data[0]
    },
    async getProductLevelTwo(id) {
      var res = await this.configurationProductLevelStore.getProductLevelTwo(id, this.userStore.token)
      this.product = res.data[0]
    },
    async getProductLevelThree(id) {
      var res = await this.configurationProductLevelStore.getProductLevelThree(id, this.userStore.token)
      this.product = res.data[0]
    },
    closeModal() {
      this.$emit('close')
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    onFileChange(e) {
      this.files = e.target.files || e.dataTransfer.files
      if (!this.files.length) return
      this.createImage(this.files[0])
    },
    removeImage: function () {
      this.imageRegular = ''
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      if (file.type.match('\pdf')) {
        reader.onload = () => {
          this.imageRegular = this.pdf
        }
        reader.readAsDataURL(file)
        this.pdfName = file.name
        return
      }

      reader.onload = (e) => {
        vm.imageRegular = e.target.result
      }
      reader.readAsDataURL(file)

      this.pdfName = ''
    },
    async uploadImage() {
      var res = await this.configurationProductLevelStore.uploadProductLevelOneImage(
        this.files[0],
        this.userStore.token,
        this.product.ID,
      )
      if (res.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('errorUploadImage'),
          html: res.statusText,
        }).then((result) => {
          return result
        })
        return
      } else {
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('successUploadImage'),
        }).then((result) => {
          return result
        })
        return
      }
    },
    async getImage() {
      const token = this.userStore.token
      var idFromTable = this.product.ID
      var tableName = 'product_level_one'
      var resDataFile = await this.companyStore.getFileReferenceByTable(idFromTable, tableName, token)
      if (resDataFile.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('errorDownloadImage'),
        }).then((result) => {
          return result
        })
        return
      }
      var dataFileID = resDataFile.data[0].DataFileID
      var resFile = await this.companyStore.getDownloadFile(dataFileID, false, token)
      if (resFile.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('errorDownloadImage'),
        }).then((result) => {
          return result
        })
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(resFile.data)
      reader.onload = () => {
        this.imageRegular = reader.result
      }
    },
  },
  computed: {},
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    return { companyStore, userStore, configurationProductLevelStore }
  },
}
</script>

<style scoped lang="scss">
.edit-product-level-content {
  max-width: 400px;
  margin: auto;
}
[data-background-color='black'] {
  .title {
    color: white !important;
  }
}
.image-container {
  max-width: 100px;
  margin-bottom: 10px;
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);

  .img-circle & {
    border-radius: 50%;
    max-width: 100px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  &,
  img {
    border-radius: 4px;
  }
}
.card-content {
  display: flex;
  flex-direction: column;
}
.title {
  width: 300px;
}
.md-layout-item {
  display: flex !important;
  justify-content: center !important;
}
</style>
