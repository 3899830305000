<template>
  <div class="protocol-content">
    <loader :loader="loader" />
    <md-card class="md-card-configuration-protocol">
      <md-card-header class="md-card-header-icon md-card-header-primary">
        <div @click="postModels" class="card-icon">
          <md-icon>content_paste</md-icon>
        </div>
        <h4 class="">{{ $t('protocol') }}</h4>
      </md-card-header>
      <md-card-content>
        <!-- <md-field>
          <div class="md-layout controls">
            <div class="md-layout-item md-size-100">
              
            </div>
          </div>
        </md-field> -->
        <div class="controls">
          <md-switch v-model="showAll">{{ $t('showAll') }}</md-switch>
        </div>
        <div class="md-layout protocol-table">
          <div class="md-layout md-layout-item md-size-100">
            <div class="md-layout-item md-size-35">{{ $t('Name') }}</div>
            <div class="md-layout-item md-size-25">ID</div>
            <div class="md-layout-item md-size-10">{{ $t('count') }}</div>
            <div class="md-layout-item md-size-10">{{ $t('endDate') }}</div>
            <div class="md-layout-item md-size-20"></div>
          </div>

          <div
            class="md-layout md-layout-item md-size-100"
            v-for="p in licSelected"
            :key="p.ConfigurationHasProductLevelOne.ID"
          >
            <div class="md-layout md-layout-item md-size-100 protocol-header">
              <div class="md-layout-item md-size-35">{{ p.ProductLevelOne.Name }}</div>
              <div class="md-layout-item md-size-25">
                {{
                  (p.ProductLevelOne.LicName !== '' ? p.ProductLevelOne.LicName : p.ProductLevelOne.LicNameParent) +
                  version
                }}
              </div>
              <div class="md-layout-item md-size-10">
                {{
                  p.ConfigurationHasProductLevelOne.Count === 9999
                    ? 'uncounted'
                    : p.ConfigurationHasProductLevelOne.Count
                }}
              </div>
              <div class="md-layout-item md-size-20">
                {{
                  p.ConfigurationHasProductLevelOne.Limited === '9999-12-31'
                    ? 'permanent'
                    : p.ConfigurationHasProductLevelOne.Limited.split('T')[0]
                }}
              </div>
              <div class="md-layout-item md-size-10">
                {{ `${p.ConfigurationHasProductLevelOne.Connect === 1 ? $t('Connect') : ''}` }}
              </div>
            </div>

            <div class="md-layout md-layout-item md-size-100" v-if="p.ListProtocolLevelTwo.length > 0">
              <div
                class="md-layout md-layout-item md-size-100"
                :class="{ 'dependency ': l2.isDependency, hide: l2.isDependency && !showAll }"
                v-for="l2 in p.ListProtocolLevelTwo"
                :key="l2.ConfigurationHasProductLevelTwo.ID"
              >
                <div v-if="l2.ConfigurationHasProductLevelTwo.Count > 0" class="md-layout-item md-size-35 fs-italic">
                  {{ l2.ProductLevelTwo.Name }}
                </div>
                <div v-if="l2.ConfigurationHasProductLevelTwo.Count > 0" class="md-layout-item md-size-25">
                  {{ l2.ProductLevelTwo.LicName }}
                </div>
                <div v-if="l2.ConfigurationHasProductLevelTwo.Count > 0" class="md-layout-item md-size-10">
                  {{
                    l2.ConfigurationHasProductLevelTwo.Count === 9999
                      ? 'uncounted'
                      : l2.ConfigurationHasProductLevelTwo.Count
                  }}
                </div>
                <div v-if="l2.ConfigurationHasProductLevelTwo.Count > 0" class="md-layout-item md-size-20">
                  {{
                    l2.ConfigurationHasProductLevelTwo.Limited === '9999-12-31'
                      ? 'permanent'
                      : l2.ConfigurationHasProductLevelTwo.Limited.split('T')[0]
                  }}
                </div>
                <div v-if="l2.ConfigurationHasProductLevelTwo.Count > 0" class="md-layout-item md-size-10"></div>
                <div
                  class="md-layout md-layout-item md-size-100 module-block"
                  v-if="l2.ListProtocolLevelThree.length > 0 && l2.ConfigurationHasProductLevelTwo.Count > 0"
                >
                  <div
                    class="md-layout md-layout-item md-size-100"
                    v-for="l3 in l2.ListProtocolLevelThree"
                    :key="l3.ConfigurationHasProductLevelThree.ID"
                  >
                    <div v-if="l3.ConfigurationHasProductLevelThree.Count > 0" class="md-layout-item md-size-35 pl-5">
                      {{ '- ' + l3.ProductLevelThree.Name }}
                    </div>
                    <div v-if="l3.ConfigurationHasProductLevelThree.Count > 0" class="md-layout-item md-size-25">
                      {{ l3.ProductLevelThree.LicName }}
                    </div>
                    <div v-if="l3.ConfigurationHasProductLevelThree.Count > 0" class="md-layout-item md-size-10">
                      {{
                        l3.ConfigurationHasProductLevelThree.Count === 9999
                          ? 'uncounted'
                          : l3.ConfigurationHasProductLevelThree.Count
                      }}
                    </div>
                    <div v-if="l3.ConfigurationHasProductLevelThree.Count > 0" class="md-layout-item md-size-20">
                      {{
                        l3.ConfigurationHasProductLevelThree.Limited === '9999-12-31'
                          ? 'permanent'
                          : l3.ConfigurationHasProductLevelThree.Limited.split('T')[0]
                      }}
                    </div>
                    <div v-if="l3.ConfigurationHasProductLevelThree.Count > 0" class="md-layout-item md-size-10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="md-layout md-layout-item md-size-100"
            :class="{ 'dependency ': p.isDependency, hide: p.isDependency && !showAll }"
            v-for="p in licDependencies"
            :key="p.ProductLevelOne.ID"
          >
            <div class="md-layout md-layout-item md-size-100 protocol-header">
              <div class="md-layout-item md-size-35">{{ p.ProductLevelOne.Name.split('techDependency ')[1] }}</div>
              <div class="md-layout-item md-size-25">
                {{
                  (p.ProductLevelOne.LicName !== '' ? p.ProductLevelOne.LicName : p.ProductLevelOne.LicNameParent) +
                  version
                }}
              </div>
              <div class="md-layout-item md-size-10">{{ p.ConfigurationHasProductLevelOne.Count }}</div>
              <div class="md-layout-item md-size-20">
                {{
                  p.ConfigurationHasProductLevelOne.Limited === '9999-12-31'
                    ? 'permanent'
                    : p.ConfigurationHasProductLevelOne.Limited.split('T')[0]
                }}
              </div>
              <div class="md-layout-item md-size-10"></div>
            </div>

            <div class="md-layout md-layout-item md-size-100" v-if="p.ListProtocolLevelTwo.length > 0">
              <div
                class="md-layout md-layout-item md-size-100"
                :class="{ 'dependency ': l2.isDependency, hide: p.isDependency && !showAll }"
                v-for="l2 in p.ListProtocolLevelTwo"
                :key="l2.ConfigurationHasProductLevelTwo.ID"
              >
                <div class="md-layout-item md-size-35 fs-italic">
                  {{ '- ' + l2.ProductLevelTwo.Name.split('techDependency ')[1] }}
                </div>
                <div class="md-layout-item md-size-25">{{ l2.ProductLevelTwo.LicName }}</div>
                <div class="md-layout-item md-size-10">{{ l2.ConfigurationHasProductLevelTwo.Count }}</div>
                <div class="md-layout-item md-size-20">
                  {{
                    l2.ConfigurationHasProductLevelTwo.Limited === '9999-12-31'
                      ? 'permanent'
                      : l2.ConfigurationHasProductLevelTwo.Limited.split('T')[0]
                  }}
                </div>
                <div class="md-layout-item md-size-10"></div>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '@/stores/user'
import { useConfigurationProductLevelStore } from '@/stores/configurationProductLevel'

export default {
  components: {},
  data() {
    return {
      loader: false,
      version: '',
      licSelected: [],
      licDependencies: [],
      showAll: false,
      // licNotSelected: [],
    }
  },
  async beforeMount() {
    const token = this.userStore.token

    const configurationID = this.configurationProductLevelStore.protocolData.ConfigurationID
    const version = this.configurationProductLevelStore.protocolData.Version
    const versionType = this.configurationProductLevelStore.protocolData.VersionType

    const resProtocol = await this.configurationProductLevelStore.getProtocolByConfiguration(configurationID, token)

    const selected = resProtocol.data

    this.licSelected = selected

    this.licSelected.forEach((selected) => {
      if (selected.ProductLevelOne.Name.includes('techDependency')) {
        selected.isDependency = true
        this.licSelected = this.licSelected.filter((i) => i.ProductLevelOne.ID !== selected.ProductLevelOne.ID)
        this.licDependencies.push(selected)
      }
    })
    const resDependencies = await this.configurationProductLevelStore.getProtocolTechDependencys(
      version,
      versionType,
      token,
    )
    const dependencies = resDependencies.data
    //this.licSelected = this.configurationProductLevelStore.licSelected
    // this.licDependencies = this.configurationProductLevelStore.licDependencies

    // this.licDependencies = dependencies
    // this.licDependencies.forEach((item) => {
    //   item.isDependency = true
    // })

    // this.licNotSelected = this.configurationProductLevelStore.licNotSelected
    this.version = this.configurationProductLevelStore.product.LicName
  },
  methods: {
    postModels() {
      var copySelected = JSON.parse(JSON.stringify(this.licSelected))
      var copyDependencies = JSON.parse(JSON.stringify(this.licDependencies))
    },
  },
  computed: {},
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    return { companyStore, userStore, configurationProductLevelStore }
  },
}
</script>
<style scoped lang="scss">
.md-layout-item {
  padding: 0;
}
.fs-italic {
  font-style: italic;
}
.protocol-header {
  font-weight: bold;
}
[data-background-color='black'] {
  .protocol-header {
    background: lightslategray;
  }
  .module-block {
    border-bottom: 1px solid white;
  }
}
[data-background-color='white'] {
  .protocol-header {
    background: rgb(211, 211, 211);
  }
  .module-block {
    border-bottom: 1px solid black;
  }
}
.dependency {
  color: red;
}
.hide {
  display: none;
}
.pl-5 {
  padding-left: 5px;
}
.protocol-content {
  display: flex;
  justify-content: center;
}
.md-card-configuration-protocol {
  max-width: 1000px;
}
</style>
